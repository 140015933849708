import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '../messages/Tooltip';

const Button = ({
  text = '',
  color = 'red',
  icon = '',
  iconRight = false,
  loading = false,
  disabled = false,
  className = '',
  noMargin = false,
  selected = false,
  tooltip = '',
  onClick,
  extraStyle,
}) => {
  let classes = `button ${color} ${className}`;
  let loadingContent, textContent;

  if (icon) classes += ` icon-${icon}`;
  if (icon && iconRight) classes += ' right-icon';
  if (disabled) classes += ' disabled';
  if (noMargin) classes += ' no-gutter-bottom';
  if (selected) classes += ' selected';

  if (loading) {
    classes += ' loading disabled';
    loadingContent = (
      <span className="spinner-container">
        <i className="icon-spinner2" />
      </span>
    );
  }

  if (!text) {
    classes += ' no-text';
  } else {
    textContent = <span style={{ visibility: loading ? 'hidden' : 'visible' }}>{text}</span>;
  }

  return (
    <span style={extraStyle}>
      {tooltip && <Tooltip id="buttonTip" />}
      <span data-for="buttonTip" data-tip={tooltip}>
        <button className={classes} onClick={!disabled && !loading ? onClick : null} disabled={disabled || loading}>
          {loadingContent}
          {textContent}
        </button>
      </span>
    </span>
  );
};

Button.propTypes = {
  text: PropTypes.string,
  tooltip: PropTypes.string,
  buttonType: PropTypes.oneOf(['button', 'link']),
  color: PropTypes.oneOf([
    'black',
    'gray-light',
    'gray',
    'green',
    'blue',
    'gray-dark',
    'white',
    'white-blue',
    'clear',
    'transparent',
    'red',
  ]),
  icon: PropTypes.string,
  iconRight: PropTypes.bool,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  noMargin: PropTypes.bool,
  selected: PropTypes.bool,
};

export default Button;
