import React, { useState, useEffect } from 'react';
import { useTranslation } from 'jsx/hooks/useTranslation';
import Grid from '@mui/material/Unstable_Grid2';
import { Frame } from 'pages/Frame';
import { Typography, useMediaQuery } from '@mui/material';
import { apiDecision } from 'api/apiDecision';
import { UserInput } from 'components/UserInput';

export const General = ({
  setCurrentStep,
  currentStep,
  applicationID,
  trail,
  subscriber,
  confirmStep,
  cancelApplication,
  previousStep,
  noSubscriberParameterID,
  setNoSubscriberParameterID,
}) => {
  const { t, loaded } = useTranslation(2026);
  const [validation, setValidation] = useState({}); //The validation for all of the input parameters.
  const parameters = currentStep.userInputParameters;
  const currentStepID = currentStep.stepId;
  const matches = useMediaQuery('(min-width:800px)');
  const [errorMessage, setErrorMessage] = useState('');
  const [waitingOnPending, setWaitingOnPending] = useState();

  const toHtml = (htmlStr) => ({ __html: htmlStr });
  useEffect(() => {
    checkStepValidation();
  }, [currentStepID]);

  useEffect(() => {
    if (waitingOnPending) {
      setWaitingOnPending(false);
      onConfirm();
    }
  }, [validation, waitingOnPending]);

  function checkStepValidation() {
    setNoSubscriberParameterID('');
    const validationRules = currentStep?.validationRules.length > 0 ? currentStep.validationRules[0] : null;

    if (validationRules?.validationDate) {
      let inValidValidations = validationRules?.validations?.filter(
        (validation) =>
          validation.subscriberParameterID && !validation.valid && dependencyCheck(validation.subscriberParameterID)
      );
      if (inValidValidations) {
        inValidValidations.forEach((v) => {
          setValidation((prev) => ({
            ...prev,
            [v.subscriberParameterID]: t(
              `userInputParameter_${v.subscriberParameterID}_inputValidation_${v.validationId}`
            ),
          }));
        });
      }

      const firstInvalidValidationWithNoSubscriberParemeterId = validationRules?.validations?.find(
        (validation) => !validation.subscriberParameterID && validation.valid === false
      );
      if (firstInvalidValidationWithNoSubscriberParemeterId) {
        setErrorMessage(
          t(`general_validate_errorMessage_${firstInvalidValidationWithNoSubscriberParemeterId.validationId}`)
        );
      }
    }
  }

  function dependencyCheck(subscriberParameterID, parameters) {
    const subscriberParameter = parameters && parameters.find((p) => p.subscriberParameterID === subscriberParameterID);

    if (!subscriberParameter || !subscriberParameter.dependency) {
      // No dependency exists, always show the parameter
      return true;
    }

    return subscriberParameter.dependency.dependencyParameters.every((dep) => {
      const parentParameter = parameters.find((p) => p.subscriberParameterID === dep.subscriberParameterID);
      if (!parentParameter || parentParameter.value === null) {
        // If parent parameter is not found or its value is not set, fail the check
        return false;
      }
      const parentValue = parseInt(parentParameter.value, 10);
      const dependencyValue = parseInt(dep.value, 10);

      // Evaluate the condition based on the operator
      let conditionMet = false;
      switch (dep.operator) {
        case '>=':
          conditionMet = parentValue >= dependencyValue;
          break;
        case '==':
        default:
          conditionMet = parentValue === dependencyValue;
          break;
      }
      // If condition is met, recursively check if the parent parameter itself has dependencies that are met
      return conditionMet && dependencyCheck(parentParameter.subscriberParameterID, parameters);
    });
  }

  const onConfirm = async () => {
    let applicableParams = parameters.filter((p) => dependencyCheck(p.subscriberParameterID, parameters));
    let applicableParamIDs = applicableParams.map((p) => p.subscriberParameterID);
    let applicableValidations = Object.entries(validation).filter((v) => applicableParamIDs.includes(Number(v[0])));

    let requiredEmptyParams = applicableParams.filter(
      (p) => p.isRequired !== false && (p.value === null || p.value === undefined || p.value === '')
    );
    if (requiredEmptyParams.length > 0) {
      requiredEmptyParams.forEach((p) => {
        setValidation((prev) => ({
          ...prev,
          [p.subscriberParameterID]: { type: 'error', message: t('general_validate_required') },
        }));
      });
      return;
    }

    let notValidatedParams = applicableValidations.filter((v) => v[1]?.type === 'notValidated');
    if (notValidatedParams.length > 0) {
      notValidatedParams.forEach((notValidated) => {
        setValidation((prev) => ({
          ...prev,
          [notValidated[0]]: {
            type: 'error',
            message: t(`userInputParameter_${subscriber?.subscriberID}_${notValidated[0]}_inputValidation_invalid`),
          },
        }));
      });
      return;
    }

    if (applicableValidations.some((v) => v[1]?.type === 'pending')) {
      setWaitingOnPending(true);
    } else if (!applicableValidations.some((v) => v[1] != null)) {
      const saveReq = {
        applicationID: applicationID,
        stepID: currentStepID,
        userInputParameters: applicableParams,
      };
      apiDecision.saveUserInputParameters(saveReq).then((res) => {
        if (!res.error) {
          const request = {
            applicationID: applicationID,
            stepId: currentStepID,
          };
          confirmStep(request);
        }
      });
    }
  };

  if (!loaded) return null;
  return (
    <Frame
      currentStep={currentStep}
      trail={trail}
      subscriber={subscriber}
      title={t(`general_header_${subscriber?.subscriberID}_${currentStepID}`)}
      leftButtonProps={{ action: cancelApplication, text: t('cancel') }}
      rightButtonProps={{
        action: onConfirm,
        text: t('button_confirm'),
      }}
      middleButtonProps={
        currentStep?.stepOrchestration?.backingAllowed === false
          ? null
          : { action: previousStep, text: t('button_goBack') }
      }
    >
      <Grid
        container
        spacing={4}
        justifyContent={currentStep?.alignment === 2 && 'center'}
        alignItems={currentStep?.alignment === 2 && 'center'}
      >
        <Grid item xs={12} md={6}>
          <Typography variant="h5">{t(`general_title_${subscriber?.subscriberID}_${currentStepID}`)}</Typography>
          <br></br>
          <Typography
            dangerouslySetInnerHTML={toHtml(t(`general_desc_${subscriber?.subscriberID}_${currentStepID}`))}
          />

          {matches && currentStep?.alignment !== 2 && (
            <img
              src={`${process.env.REACT_APP_WEB_DECISION_THEME_IMG_URL}/decision/${subscriber?.subscriberID}/generalPageImage.svg`}
              height="353px"
              width="359px"
            />
          )}
        </Grid>
        {currentStep?.alignment !== 2 && (
          <UserInput
            setCurrentStep={setCurrentStep}
            parameters={parameters}
            dependencyCheck={dependencyCheck}
            currentStep={currentStep}
            subscriber={subscriber}
            applicationID={applicationID}
            currentStepID={currentStepID}
            validation={validation}
            setValidation={setValidation}
            noSubscriberParameterID={noSubscriberParameterID}
          />
        )}
      </Grid>
      {currentStep?.alignment === 2 && (
        <Grid container spacing={4} justifyContent="center" alignItems="center">
          <UserInput
            setCurrentStep={setCurrentStep}
            parameters={parameters}
            dependencyCheck={dependencyCheck}
            currentStep={currentStep}
            subscriber={subscriber}
            applicationID={applicationID}
            currentStepID={currentStepID}
            validation={validation}
            setValidation={setValidation}
            noSubscriberParameterID={noSubscriberParameterID}
          />
        </Grid>
      )}
    </Frame>
  );
};
