import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { useAuthContext } from 'jsx/auth/AuthProviderV2';
import LogoutIcon from '@mui/icons-material/Logout';
import { Button, useTheme } from '@mui/material';

export const Header = ({ src, subscriber }) => {
  const { logOut } = useAuthContext();
  const theme = useTheme();

  const logOutAndRedirect = () => {
    let redirectTo = `DECISION_POST_LOGOUT_REDIRECT_${subscriber.subscriberID}`;
    logOut(redirectTo);
  };
  return (
    <AppBar
      position="static"
      sx={{ backgroundColor: theme.palette.header.main, boxShadow: '0 0 6px -2px rgb(0 0 0 / 20%)' }}
    >
      <Toolbar>
        <div style={{ flexGrow: 1 }}>
          <img height={100} width={170} src={src} />
        </div>
        <Button
          variant="standard"
          size="large"
          sx={{ color: theme.palette.headerIcon.main }}
          onClick={logOutAndRedirect}
          endIcon={<LogoutIcon />}
        />
      </Toolbar>
    </AppBar>
  );
};
