import { useEffect, useRef } from 'react';

import { useAuthContext } from './AuthProviderV2';

export default ({ history }) => {
  const { loginCallback, logOut, setUser } = useAuthContext();
  const effectRan = useRef(false);

  useEffect(() => {
    if (effectRan.current === false) {
      loginCallback(history.location.hash)
        .then(history.push)
        .then(setUser)
        .catch((ex) => {
          console.log(ex);
          logOut();
        });

      return () => {
        effectRan.current = true;
      };
    }
   
  }, []);

  return null;
};
