import { apiPostCall } from '@creditinfo/react-common-lib';
import request from 'utils/api/apiRequest';
const KYC_BASE_URL = process.env.REACT_APP_KYC_URL;
const KYC_URL = `${KYC_BASE_URL}/KnowYourCustomer`;

export const apiKYC = {
  getForm: (guid) => {
    return request(`${KYC_URL}/GetForm?guid=${guid}`);
  },
  requestPDFSigning: (data) => {
    return request(`${KYC_URL}/RequestPDFSigning`, { method: 'post', data: data });
  },
  checkSignatureStatus: (guid, waitTimeInSeconds) => {
    return request(`${KYC_URL}/CheckSignatureStatus/${guid}/timeout/${waitTimeInSeconds}`);
  },
};
if (process.env.NODE_ENV === 'development') {
  window.apiKYC = apiKYC;
}
