import React, { useEffect, useState } from 'react';
import { useTranslation } from 'jsx/hooks/useTranslation';
import { Typography, useMediaQuery, Link } from '@mui/material';
import { Frame } from 'pages/Frame';
import Grid from '@mui/material/Unstable_Grid2';
import { AlertPanel } from '@creditinfo/react-common-lib';
import { b64ToPdfFile } from 'utils/api/fetchPdfBinary';
import { apiDecision } from 'api/apiDecision';
import { parseDate } from 'utils/date';

export const Result = ({
  currentStep,
  applicationID,
  trail,
  subscriber,
  getContractPDF,
  confirmStep,
  cancelApplication,
  previousStep,
}) => {
  const { t } = useTranslation(2026);
  const currentStepID = currentStep.stepId;
  const toHtml = (htmlStr) => ({ __html: htmlStr });
  const matches = useMediaQuery('(min-width:800px)');
  const [pdfResult, setPdfResult] = useState(null);

  useEffect(() => {
    apiDecision.getContract(applicationID).then((res) => {
      b64ToPdfFile(res.contractSignedPDF, `Samningur ${parseDate(new Date())}.pdf`);
      setPdfResult(res);
    });
  }, []);

  let resultStatus = null;
  if (currentStep.validationRules && currentStep.validationRules.length > 0)
    currentStep.validationRules[0].validations.every((item) => {
      if (item.name === 'decisionCompleted' && !item.valid) {
        resultStatus = {
          status: 'failed',
          fileIcon: 'fileWarning',
          color: 'warning',
          alertText: t(`result_failed_title_${subscriber.subscriberID}`),
          textToDisplay: t(`result_failed_desc_${subscriber.subscriberID}`),
        };
        return false;
      } else if (item.name === 'decisionPassed' && !item.valid) {
        resultStatus = {
          status: 'rejection',
          fileIcon: 'fileError',
          color: 'error',
          alertText: t(`result_rejection_title_${subscriber.subscriberID}`),
          textToDisplay: t(`result_rejection_desc_${subscriber.subscriberID}`),
        };
        return false;
      } else if (item.name === 'decisionSeverityWarning' && item.valid) {
        resultStatus = {
          status: 'tobeReviewed',
          fileIcon: 'fileInfo',
          color: 'info',
          alertText: t(`result_tobeReviewed_title_${subscriber.subscriberID}`),
          textToDisplay: t(`result_tobeReviewed_desc_${subscriber.subscriberID}`),
        };
        return false;
      } else if (item.name === 'decisionSeverityWarning' && !item.valid) {
        resultStatus = {
          status: 'success',
          fileIcon: 'fileSuccess',
          color: 'success',
          alertText: t(`result_success_title_${subscriber.subscriberID}`),
          textToDisplay: t(`result_success_desc_${subscriber.subscriberID}`),
        };
        return false;
      }
      return true;
    });
  else if (currentStep.valid === true) {
    resultStatus = {
      status: 'success',
      fileIcon: 'fileSuccess',
      color: 'success',
      alertText: t(`result_success_title_${subscriber.subscriberID}`),
      textToDisplay: t(`result_success_desc_${subscriber.subscriberID}`),
    };
  }

  const confirm = () => {
    const request = {
      applicationID: applicationID,
      stepId: currentStepID,
    };
    confirmStep(request);
  };
  if (!resultStatus) return null;
  return (
    <>
      <Frame
        currentStep={currentStep}
        trail={trail}
        subscriber={subscriber}
        currentStepID={currentStepID}
        rightButtonProps={
          resultStatus && resultStatus.status === 'failed'
            ? { action: confirm, text: t('try_again') }
            : { action: cancelApplication, text: t('close') }
        }
        middleButtonProps={
          currentStep?.stepOrchestration?.backingAllowed === false
            ? null
            : { action: previousStep, text: t('button_goBack') }
        }
      >
        <Grid container spacing={2} alignItems={'center'}>
          <Grid md={6} xs={12}>
            <AlertPanel severity={resultStatus && resultStatus.color}>
              {resultStatus && resultStatus.alertText}
            </AlertPanel>
            <Typography sx={{ paddingBottom: '10px' }}>
              <span dangerouslySetInnerHTML={toHtml(resultStatus && resultStatus.textToDisplay)} />
            </Typography>
            {resultStatus && resultStatus.status === 'success' && pdfResult && (
              <Link
                href={pdfResult && pdfResult}
                target="_blank"
                rel="noreferrer"
                onClick={(e) => {
                  e.preventDefault();
                  getContractPDF();
                }}
                download
                variant="subtitle1"
              >
                {t('copy_of_contract')} <i className="icon-file-pdf" style={{ paddingLeft: '10px' }} />
              </Link>
            )}
          </Grid>
          {matches && (
            <Grid md={6} xs={12} sx={{ textAlign: 'right' }}>
              <img
                src={`${process.env.REACT_APP_WEB_DECISION_THEME_IMG_URL}/decision/${subscriber.subscriberID}/${
                  resultStatus && resultStatus.fileIcon
                }.svg`}
                height="350px"
                width="350px"
              />
            </Grid>
          )}
        </Grid>
      </Frame>
    </>
  );
};
