import { creditInfo, theme3326, theme3622, theme10806 } from '@creditinfo/react-common-lib';

const themes = {
  creditInfo,
  theme3326,
  theme3622,
  theme10806,
};

export default function getTheme(theme) {
  return themes[theme];
}
