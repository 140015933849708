import axios from 'axios';

export default (url, options = {}) => {
  const config = {
    url,
    method: options.method || 'get',
    responseType: options.responseType || 'json',
    headers: options.headers || {},
  };

  if (options.signal) {
    config.signal = options.signal;
  }
  if (options.cancelToken) {
    config.cancelToken = options.cancelToken;
  }

  config.headers['Content-Type'] = options.contentType || 'application/json';

  if (!!window.MSInputMethodContext && !!document.documentMode) {
    /**
     * Internet Explorer is fucking stupid. If I dont set these headers,
     * many GET requests are just cached by IE11. That means
     * certain conditionals keep firing even if the user
     * has resolved them because a GET request to an endpoint
     * that was requested recently, still shows the same response
     * even if the user made some changes in the meantime......
     */
    config.headers['Cache-Control'] = 'no-cache';
    config.headers['Pragma'] = 'no-cache';
    config.headers['Expires'] = 'Sat, 01 Jan 2000 00:00:00 GMT';
  }

  if (options.data) {
    if (options.formData) {
      let fd = new FormData();
      fd.append(options.fieldName || 'file', options.data);
      config.data = fd;
    } else {
      if (!options.contentType) {
        config.data = JSON.stringify(options.data);
      } else {
        config.data = options.data;
      }
    }
  }

  return axios(config)
    .then((response) => {
      return response.data !== undefined ? response.data : response;
    })
    .catch((err) => {
      let error = {
        code: -1,
        message: 'Villa kom upp.',
      };

      if (err.code === 'ERR_CANCELED' || axios.isCancel(err)) {
        error.code = err.code;
        error.message = err.message;
      } else {
        let response = err.response;

        if (response) {
          error.status = response.status;
          error.statusText = response.statusText;
          if (response.data) {
            error.error = response.data.error;
            error.error_id = response.data.error_id;
            error.logID = response.data.logID;
            error.error_details = response.data.error_details;
            error.error_description = response.data.error_description;
          }
        }
      }
      return {
        error,
      };
    });
};
