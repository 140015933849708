import React from 'react';
import { Typography, Radio, RadioGroup, FormControlLabel, FormControl, Link, InputLabel } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

export const RadioButtons = ({ param, subscriber, t, validation, setValidation }) => {
  const onChange = (value, param) => {
    setValidation({
      ...validation,
      [param.subscriberParameterID]: null,
    });
    param.value = value;
  };

  function wrapUrl(text) {
    if (text.includes('http') || text.includes('https') || text.includes('www')) {
      let match = text.match(/((?:https?|ftp|mailto):\/\/[^\s]+)/g);
      let index = text.indexOf(match[0]);
      let url = match[0];
      let domain = new URL(url);
      domain = domain.hostname.replace('www.', '');
      return (
        <Typography id="demo-error-radios" variant="subtitle1" sx={{ margin: '10px' }}>
          {text.substring(0, index)}
          <Link target="_blank" href={url}>
            {domain}  <span style={{color: 'red'}}>{param.isRequired ? ' *' : ''}</span>
          </Link>
          {text.substring(index + url.length, text.length)}
        </Typography>
      );
    } else {
      return (
        <Typography
          error={validation[param.subscriberParameterID] ? true : false}
          id="demo-error-radios"
          variant="subtitle1"
          sx={{ marginBottom: '10px' }}
        >
          {t(`userInputParameter_${subscriber.subscriberID}_${param.subscriberParameterID}`)} <span style={{color: 'red'}}>{param.isRequired ? ' *' : ''}</span>
        </Typography>
      );
    }
  }

  return (
    <Grid xs={12} sx={{paddingLeft: param.dependency?.dependencyParameters?.length > 0 ? '40px' : ''}}>
      <FormControl error={validation[param.subscriberParameterID] ? true : false} fullWidth>
        {wrapUrl(t(`userInputParameter_${subscriber.subscriberID}_${param.subscriberParameterID}`)) }
        <RadioGroup
          aria-labelledby="demo-error-radios"
          value={param.value}
          onChange={(event) => onChange(JSON.parse(event.target.value), param)}
        >
          {param.possibleValues?.map((option, index) => (
            <FormControlLabel
              error={validation[param.subscriberParameterID] ? true : false}
              key={index}
              value={option.value.toString()}
              label={t(
                `userInputParameter_${subscriber.subscriberID}_${param.subscriberParameterID}_possibleValue_${option.value}`
              )}
              control={<Radio />}
              disabled={option.enabled === false}
            />
          ))}
        </RadioGroup>
      </FormControl>
      <InputLabel
        variant="standard"
        sx={{ paddingTop: '10px' }}
        error={validation[param.subscriberParameterID] ? true : false}
      >
        {validation[param.subscriberParameterID] ? validation[param.subscriberParameterID].message : ''}
      </InputLabel>
    </Grid>
  );
};
