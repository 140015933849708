import React, { useState, useEffect } from 'react';
import { apiDecision } from 'api/apiDecision';
import Grid from '@mui/material/Unstable_Grid2';
import { Typography, Button, List, ListItem, IconButton, ListItemText } from '@mui/material';
import { FileUploadDialog } from '@creditinfo/react-common-lib';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { DeleteOutline } from '@mui/icons-material';

export const AttachmentInput = ({
  applicationID,
  currentStepID,
  currentStep,
  t,
  subscriberID,
  setValidation,
  validation,
}) => {
  const [attachments, setAttachments] = useState([]);
  const [fileUploadDialogOpen, setFileUploadDialogOpen] = useState(false);

  useEffect(() => {
    if (currentStep && currentStep.stepAttachment && currentStep.stepAttachment.attachmentEnabled) {
      refreshAttachments();
    }
  }, []);

  const refreshAttachments = () => {
    apiDecision.getAttachments(applicationID, currentStepID).then((res) => {
      if (res && !res.error) {
        setAttachments(res);
      }
    });
  };

  const deleteAttachment = (attachment) => {
    apiDecision.deleteAttachment(applicationID, attachment.attachmentDocumentID).then((res) => {
      refreshAttachments();
    });
  };

  const onAttachmentClick = (attachment) => {
    apiDecision.getAttachment(applicationID, attachment.attachmentDocumentID);
  };

  const handleFileUpload = (file) => {
    const fileData = {
      applicationID: applicationID,
      stepID: currentStepID,
      file: file.data,
      fileName: file.name,
      documentTypeID: currentStep.stepAttachment.allowedDocumentTypes.find((adt) => adt.extension == file.extension)
        .documentTypeID,
      documentCategoryID: currentStep.stepAttachment.allowedDocumentCategories.find(
        (adc) => adc.name == file.category.replace(/\n/g, '')
      ).documentCategoryID,
      description: file.description,
    };
    setFileUploadDialogOpen(false);
    apiDecision.addAttachment(applicationID, fileData).then((res) => {
      if (res && !res.error) {
        refreshAttachments();
      }
    });
  };

  return (
    <Grid xs={12}>
      <Typography variant="subtitle1">{t(`${subscriberID}_${currentStepID}_attachment_header`)}</Typography>
      <Button
        variant="contained"
        onClick={() => setFileUploadDialogOpen(true)}
        endIcon={<FileUploadIcon />}
        sx={{ marginTop: '30px', maxWidth: '200px' }}
      >
        {t('attachments_upload_title')}
      </Button>
      {attachments.length > 0 && (
        <List dense={true} sx={{ maxWidth: '230px', margin: '15px 0px' }}>
          {attachments.map((attachment, index) => (
            <ListItem
              sx={{ paddingLeft: 0.5 }}
              key={'attachment' + index}
              secondaryAction={
                <IconButton aria-label="delete" onClick={() => deleteAttachment(attachment)}>
                  <DeleteOutline />
                </IconButton>
              }
            >
              <ListItemText primary={attachment.fileName} onClick={() => onAttachmentClick(attachment)} />
            </ListItem>
          ))}
        </List>
      )}
      {fileUploadDialogOpen && (
        <FileUploadDialog
          handleClose={() => setFileUploadDialogOpen(false)}
          linkAvailable={false}
          t={t}
          categories={currentStep.stepAttachment.allowedDocumentCategories.map((category) => {
            return t('documentCategoryID_' + category.documentCategoryID);
          })}
          acceptedExtensions={currentStep.stepAttachment.allowedDocumentTypes.map((extension) => {
            return extension.extension;
          })}
          descriptionRequired={1}
          onSubmitFile={handleFileUpload}
        />
      )}
    </Grid>
  );
};
