import React from 'react';
import { Typography, Select, FormControl, MenuItem, InputLabel } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

export const DropdownList = ({ param, subscriber, t, validation, setValidation }) => {
  const onChange = (value, param) => {
    setValidation({
      ...validation,
      [param.subscriberParameterID]: null,
    });
    param.value = value;
  };

  return (
    <Grid xs={12} sx={{ paddingLeft: param.dependency?.dependencyParameters?.length > 0 ? '40px' : '' }}>
      <Typography sx={{ marginBottom: '10px' }} variant="subtitle1">
        {t(`userInputParameter_${subscriber.subscriberID}_${param.subscriberParameterID}`)}
        <span style={{ color: 'red' }}>{param.isRequired ? ' *' : ''}</span>
      </Typography>
      <FormControl fullWidth error={!!validation[param.subscriberParameterID]}>
        <InputLabel sx={{ background: 'white', padding: '0 5px' }}>
          {validation[param.subscriberParameterID]?.message || ''}
        </InputLabel>
        {param.possibleValues?.map((selection, index) => (
          <div
            key={index}
            label={t(
              `userInputParameter_${subscriber.subscriberID}_${param.subscriberParameterID}_possibleValue_${selection.value}`
            )}
            value={selection.value.toString()}
          />
        ))}

        <Select value={param.value} onChange={(event) => onChange(event.target.value, param)}>
          {param.possibleValues?.map((selection, index) => (
            <MenuItem
              key={index}
              label={t(
                `userInputParameter_${subscriber.subscriberID}_${param.subscriberParameterID}_possibleValue_${selection.value}`
              )}
              value={selection.value}
            >
              {selection.description}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid>
  );
};
