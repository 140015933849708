import { apiPostCall } from 'utils/api/apiCall';
const BASE_URL = process.env.REACT_APP_SHARED_API_URL;
const FORM_URL = `${BASE_URL}/Form`;

export const apiShared = {
  saveForm: async (data) => {
    return apiPostCall(`${FORM_URL}/save`, data);
  },
  updateFormStatus: async (data) => {
    return apiPostCall(`${FORM_URL}/status`, data);
  },
};

if (process.env.NODE_ENV === 'development') {
  window.apiShared = apiShared;
}
