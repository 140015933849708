import React, { useEffect, useState } from 'react';
import { apiDecision } from 'api/apiDecision';
import { ApplicationApplicant } from 'pages/ApplicationApplicant';
import { Contract } from 'pages/Contract';
import { Decision } from 'pages/Decision';
import { General } from 'pages/General';
import { Result } from 'pages/Result';
import { Terms } from 'pages/Terms';
import { useAuthContext } from 'jsx/auth/AuthProviderV2';
import { parseDate } from 'utils/date';
import { b64ToPdfFile } from 'utils/api/fetchPdfBinary';
import * as Sentry from '@sentry/react';
import { KnowYourCustomer } from 'pages/KnowYourCustomer';
import { useTranslation } from 'jsx/hooks/useTranslation';

export const Application = ({ applicationStatusRequest, subscriber }) => {
  const { user, logOut } = useAuthContext();
  const [currentStep, setCurrentStep] = useState(null);
  const [applicationID, setApplicationID] = useState(null);
  const [trail, setTrail] = useState(null);
  const [subjects, setSubjects] = useState(null);
  const [validatedStep, setValidatedStep] = useState(null);
  const [pdfResult, setPdfResult] = useState(null);
  const [statusID, setStatusID] = useState(null);
  const [allStepsCompleted, setAllStepsCompleted] = useState(false);
  const [noSubscriberParameterID, setNoSubscriberParameterID] = useState('');
  const [loadingStep, setLoadingStep] = useState(false);
  const { t } = useTranslation(2026);

  useEffect(() => {
    if (applicationStatusRequest.applicantEmail !== null && applicationStatusRequest.applicantSSN !== null) {
      apiDecision.getApplicationStatus(applicationStatusRequest).then((res) => {
        if (res && !res.error) {
          setCurrentStep(res.workflow.configuration.currentStep);
          setApplicationID(res.application.applicationID);
          setStatusID(res.workflow.statusID);
          setTrail(res.workflow.configuration.trail);
          setSubjects(res.application.subjects);
          setAllStepsCompleted(res.workflow.configuration.allStepsCompleted);
        }
      });
    } else {
      apiDecision.getApplicationStatusCompanyNotKnown(applicationStatusRequest.clientID).then((res) => {
        if (res && !res.error) {
          setCurrentStep(res.workflow.configuration.currentStep);
          setApplicationID(res.application.applicationID);
          setStatusID(res.workflow.statusID);
          setTrail(res.workflow.configuration.trail);
          setSubjects(res.application.subjects);
          setAllStepsCompleted(res.workflow.configuration.allStepsCompleted);
        }
      });
    }
  }, [applicationStatusRequest && applicationStatusRequest]);

  useEffect(() => {
    if (currentStep && currentStep.autoConfirmStep) {
      const request = {
        applicationID: applicationID,
        stepId: currentStep.stepId,
      };
      confirmStep(request);
    }
  }, [currentStep && currentStep.autoConfirmStep]);

  const validateStep = (request) => {
    apiDecision.validateStep(request).then((res) => {
      if (res && !res.error) {
        setValidatedStep(res.configuration.currentStep);
      }
    });
  };

  const confirmStep = (request) => {
    setLoadingStep(true);
    apiDecision.confirmStep(request).then((res) => {
      if (res && !res.error) {
        setCurrentStep(res.configuration.currentStep);
        setTrail(res.configuration.trail);
        setStatusID(res.statusID);
        setAllStepsCompleted(res.configuration.allStepsCompleted);

        const validationRules = currentStep?.validationRules?.[0];
        const validations = validationRules?.validations || [];

        const isMissingSubscriberParameterID = validations?.some(
          (validation) => validation?.subscriberParameterID === null
        );

        if (isMissingSubscriberParameterID) {
          const missingSubscriberParameterID = validations?.find(
            (validation) => validation?.subscriberParameterID === null
          );
          if (missingSubscriberParameterID) {
            setNoSubscriberParameterID(
              t(`userInputParameter_${subscriber?.subscriberID}_${missingSubscriberParameterID?.name}`)
            );
          }
        } else {
          setNoSubscriberParameterID('');
        }
      }
      setLoadingStep(false);
    });
  };
  const cancelApplication = () => {
    if ((statusID && statusID === 3) || allStepsCompleted) {
      let redirectTo = `DECISION_POST_LOGOUT_REDIRECT_${subscriber.subscriberID}`;
      localStorage.removeItem(`webdecision_${user.u_id}_state`);
      logOut(redirectTo);
    } else {
      apiDecision.cancelApplication(applicationID).then(() => {
        let redirectTo = `DECISION_POST_LOGOUT_REDIRECT_${subscriber.subscriberID}`;
        localStorage.removeItem(`webdecision_${user.u_id}_state`);
        logOut(redirectTo);
      });
    }
  };

  const getContractPDF = () => {
    apiDecision.getContract(applicationID).then((res) => {
      b64ToPdfFile(res.contractSignedPDF, `Samningur ${parseDate(new Date())}.pdf`);
      setPdfResult(res);
    });
  };

  const previousStep = () => {
    apiDecision.previousStep(applicationID, currentStep.stepId).then((res) => {
      if (res && !res.error) {
        setCurrentStep(res.configuration.currentStep);
        setTrail(res.configuration.trail);
        setStatusID(res.statusID);
      }
    });
  };

  const componentToReturn = (stepTypeId, currentStep) => {
    try {
      switch (stepTypeId) {
        case 5:
          return (
            <ApplicationApplicant
              currentStep={currentStep}
              applicationID={applicationID}
              subscriber={subscriber}
              confirmStep={confirmStep}
              cancelApplication={cancelApplication}
              previousStep={previousStep}
            />
          );
        case 1:
          return (
            <Terms
              currentStep={currentStep}
              applicationID={applicationID}
              trail={trail}
              subscriber={subscriber}
              confirmStep={confirmStep}
              subjects={subjects}
              cancelApplication={cancelApplication}
              previousStep={previousStep}
              loadingStep={loadingStep}
              setLoadingStep={setLoadingStep}
            />
          );
        case 8:
          return (
            <General
              setCurrentStep={setCurrentStep}
              currentStep={currentStep}
              applicationID={applicationID}
              trail={trail}
              subscriber={subscriber}
              validateStep={validateStep}
              confirmStep={confirmStep}
              validatedStep={validatedStep}
              cancelApplication={cancelApplication}
              previousStep={previousStep}
              noSubscriberParameterID={noSubscriberParameterID}
              setNoSubscriberParameterID={setNoSubscriberParameterID}
            />
          );
        case 2:
          return (
            <Decision
              cancelApplication={cancelApplication}
              trail={trail}
              subscriber={subscriber}
              currentStep={currentStep}
              applicationID={applicationID}
              confirmStep={confirmStep}
            />
          );
        case 7:
          return (
            <Contract
              currentStep={currentStep}
              applicationID={applicationID}
              trail={trail}
              subscriber={subscriber}
              confirmStep={confirmStep}
              subjects={subjects}
              previousStep={previousStep}
              cancelApplication={cancelApplication}
            />
          );
        case 10:
          return (
            <Result
              currentStep={currentStep}
              applicationID={applicationID}
              subscriber={subscriber}
              trail={trail}
              cancelApplication={cancelApplication}
              getContractPDF={getContractPDF}
              pdfResult={pdfResult}
              confirmStep={confirmStep}
              previousStep={previousStep}
            />
          );
        case 11:
          return (
            <KnowYourCustomer
              currentStep={currentStep}
              applicationID={applicationID}
              subscriber={subscriber}
              trail={trail}
              cancelApplication={cancelApplication}
              subjects={subjects}
              confirmStep={confirmStep}
              previousStep={previousStep}
            />
          );
        default:
          throw new Error('Unknown step');
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  return (
    <>
      {currentStep && currentStep.stepTypeId && componentToReturn(currentStep && currentStep.stepTypeId, currentStep)}
    </>
  );
};
