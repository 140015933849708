import React from 'react';
import { useTranslation } from 'jsx/hooks/useTranslation';
import Grid from '@mui/material/Unstable_Grid2';
import { AttachmentInput } from 'components/AttachmentInput/AttachmentInput';
import { TextInput } from './TextInput';
import { NumericalInput } from './NumericalInput';
import { RegnoInput } from './RegnoInput';
import { EmailInput } from './EmailInput';
import { RadioButtons } from './RadioButtons';
import { DropdownList } from './DropdownList';
import { SliderInput } from './SliderInput';
import { BankAccountInput } from './BankAccountInput';
import { CorporateRegnoInput } from './CorporateRegnoInput/CorporateRegnoInput';
import { AlertPanel } from '@creditinfo/react-common-lib';

export const UserInput = ({
  parameters,
  dependencyCheck,
  currentStep,
  setCurrentStep,
  subscriber,
  applicationID,
  currentStepID,
  validation,
  setValidation,
  noSubscriberParameterID,
}) => {
  const { t, loaded } = useTranslation(2026);

  const setValue = (param, value) => {
    setCurrentStep({
      ...currentStep,
      userInputParameters: currentStep.userInputParameters.map((paramObj) => {
        // Check if the parameter matches the one you want to change
        if (paramObj.subscriberParameterID == param.subscriberParameterID) {
          // Update the value of the matched parameter
          return { ...paramObj, value: value };
        }
        return paramObj;
      }),
    });
  };
  if (!loaded) return null;
  return (
    <Grid item xs={12} md={6}>
      <Grid container spacing={1} sx={{ flexDirection: 'column', padding: '0 15px 0 15px' }}>
        {noSubscriberParameterID && (
          <Grid item xs={12}>
            <AlertPanel severity="error" sx={{ width: '100%' }}>
              {noSubscriberParameterID}
            </AlertPanel>
          </Grid>
        )}
        {currentStep && currentStep.stepAttachment && currentStep.stepAttachment.attachmentEnabled === true && (
          <AttachmentInput
            applicationID={applicationID}
            currentStepID={currentStepID}
            currentStep={currentStep}
            t={t}
            subscriberID={subscriber.subscriberID}
            validation={validation}
            setValidation={setValidation}
          />
        )}
        {parameters &&
          parameters.map((param, index) => {
            if (dependencyCheck(param.subscriberParameterID, parameters)) {
              if (param.inputType === 1 || param.inputType === 11)
                return (
                  <TextInput
                    key={index}
                    param={param}
                    subscriber={subscriber}
                    t={t}
                    validation={validation}
                    setValidation={setValidation}
                  />
                );
              else if (param.inputType === 2)
                return (
                  <NumericalInput
                    key={index}
                    param={param}
                    subscriber={subscriber}
                    t={t}
                    validation={validation}
                    setValidation={setValidation}
                    setValue={setValue}
                  />
                );
              else if (param.inputType === 3)
                return (
                  <RegnoInput
                    key={index}
                    param={param}
                    subscriber={subscriber}
                    t={t}
                    validation={validation}
                    setValidation={setValidation}
                    setValue={setValue}
                  />
                );
              else if (param.inputType === 4)
                return (
                  <EmailInput
                    key={index}
                    param={param}
                    subscriber={subscriber}
                    t={t}
                    validation={validation}
                    setValidation={setValidation}
                    setValue={setValue}
                  />
                );
              else if (param.inputType === 5)
                return (
                  <RadioButtons
                    key={index}
                    param={param}
                    subscriber={subscriber}
                    t={t}
                    validation={validation}
                    setValidation={setValidation}
                    setValue={setValue}
                  />
                );
              else if (param.inputType === 6)
                return (
                  <DropdownList
                    key={index}
                    param={param}
                    subscriber={subscriber}
                    t={t}
                    validation={validation}
                    setValidation={setValidation}
                    setValue={setValue}
                  />
                );
              else if (param.inputType === 7)
                return (
                  <SliderInput
                    key={index}
                    param={param}
                    subscriber={subscriber}
                    t={t}
                    validation={validation}
                    setValidation={setValidation}
                    setValue={setValue}
                  />
                );
              else if (param.inputType === 8)
                return (
                  <BankAccountInput
                    key={index}
                    param={param}
                    subscriber={subscriber}
                    t={t}
                    validation={validation}
                    setValidation={setValidation}
                    setValue={setValue}
                    applicationID={applicationID}
                  />
                );
              else if (param.inputType === 9)
                return (
                  <CorporateRegnoInput
                    key={index}
                    param={param}
                    subscriber={subscriber}
                    t={t}
                    validation={validation}
                    setValidation={setValidation}
                    setValue={setValue}
                  />
                );
              else return null;
            }
          })}
      </Grid>
    </Grid>
  );
};
