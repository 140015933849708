import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'jsx/hooks/useTranslation';
import { DigitalSignature } from '@creditinfo/react-common-lib';

export const SigningStatus = ({ status, trySigningAgain }) => {
  const { t, loaded } = useTranslation(2012);

  if (!loaded) return null;
  return (
    <>
      <DigitalSignature status={status} translate={t} trySigningAgain={() => trySigningAgain()} />
    </>
  );
};

SigningStatus.propTypes = {
  status: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};
