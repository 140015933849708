import { createBrowserHistory } from '@remix-run/router';
import qs from 'qs';

export const history = createBrowserHistory();

export const getQueryParams = () => qs.parse(history.location.search, { ignoreQueryPrefix: true, delimiter: '&' });

export const updateQueryParam = (param, value) => {
  const params = getQueryParams();
  params[param] = value;
  const newQstring = qs.stringify(params, { delimiter: '&' });
  history.push({
    pathname: history.location.pathname,
    search: `?${newQstring}`,
  });
};

export const removeQueryParam = (param) => {
  const params = getQueryParams();
  params[param] = undefined;
  const newQstring = qs.stringify(params, { delimiter: '&' });
  history.push({
    pathname: history.location.pathname,
    search: `?${newQstring}`,
  });
};
