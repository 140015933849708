import React, { useCallback, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { translationsApi } from 'utils/api/translations';
import { useLocale } from 'jsx/locale/LanguageProviderV2';

/**
 * @param {number} id Translate resource ID
 * @param {"is" | "en"} lang Override the language used for this translation.
 *                           Default uses lang from the LanguageProviderV2 context.
 *
 * Load translation for a source ID and the current language.
 * This does not load the translations into the global locale object.
 * The common strings (2009) are also loaded and the objects are merged.
 * In case of duplicates, the string from the resource trumps the shared string.
 *
 * There's also an internal cache so when multiple components request translations
 * for the same source ID, only one request is fired and the rest just get the data
 * from that response. For example, everyone loads 2009 (the shared strings) but
 * only one request is ever made to the API to load it,
 * the others just get it from memory.
 *
 * This is meant for component level translations. So for example,
 * The CookieProvider component uses source 2011 and only downloads
 * the locale needed for that component and the current language.
 *
 * Theoretically we should be able to move all translations to this
 * format at some point and just get rid of most of common (2009) as well.
 *
 * If the lang prop is not supplied, the component using this hook needs to be
 * somewhere in a react tree that's wrapped with LanguageProviderV2 so it can get
 * the language from useLocale().
 *
 * returns:
 *  t: a translation function
 *  lang: the language currently in use by the hook
 *  loaded: whether the resource locale and common locale are both loaded.
 *  locale: the text object from the api
 *  isFetching: is resource or common locale currently being fetched.
 */
export function useTranslation(id, lang = undefined) {
  const { lang: language } = useLocale();
  const usedLanguage = lang || language || 'is';
  const shared = useTranslateQuery(2009, usedLanguage);
  const local = useTranslateQuery(id, usedLanguage);

  const locale = useMemo(() => {
    if (!shared.data || !local.data) return null;
    return {
      ...shared.data,
      ...local.data,
    };
  }, [shared.data, local.data]);

  const t = useCallback(
    (str, ...args) => {
      if (SHOW_KEY || !locale) return str;
      return getReplacedStringFromLocale(locale, str, args);
    },
    [locale]
  );

  return {
    t,
    lang: usedLanguage,
    isFetching: local.isFetching || shared.isFetching,
    loaded: local.status === 'success' && shared.status === 'success',
    locale,
  };
}

const SHOW_KEY = window.location.search.includes('show_locale_string');

function useTranslateQuery(id, lang) {
  return useQuery({
    queryKey: ['translate', id, lang],
    queryFn: async ({ queryKey }) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_key, id, lang] = queryKey;
      return translationsApi.getTranslations(id, lang);
    },
    refetchOnWindowFocus: false,
    refetchInterval: false,
    retry: false,
    staleTime: Infinity,
  });
}

function getReplacedStringFromLocale(locale, key, args) {
  let s = locale[key];
  if (!s) return '';

  for (let i = 0; i < args.length; i++) {
    s = s.replace('{' + i + '}', args[i]);
  }
  return s;
}

/**
 * Fallback functions to help with migrations for class components
 *
 * 1. Use the useMCITranslation() hook in MCI/App.js instead of useTranslationAPI.
 * 2. Move every MCI component/page to use withMCILocale() instead of withLocale().
 * 3. Remove use of redux 'locale' in mapState fns since we no longer load the translations into the LanguageProvider.
 * 4. Show an individual component working with their own translations: CookieProvider.
 *
 * |--- You are here as of May 24, 2020 - siggirh.
 * v
 * 5. Gradually move every component to their own resource for withTranslation().
 * 6. ??
 * 7. Profit.
 */

export function useMCITranslation(lang = undefined) {
  return useTranslation(2001, lang);
}

export function withMCILocale(Component) {
  return function ComponentWithTranslations(props) {
    const tProps = useMCITranslation();
    return <Component {...tProps} {...props} />;
  };
}

export function withTranslation(Component, id, lang) {
  return function ComponentWithTranslations(props) {
    const tProps = useTranslation(id, lang);
    return <Component {...tProps} {...props} />;
  };
}

/**
 * Fallback for Safnid as well
 */

export function usePortfolioTranslation(lang = undefined) {
  return useTranslation(2003, lang);
}
