import isFinite from 'lodash-es/isFinite';

export const validatePassword = (newPass, confirmNewPass) => {
  // Return values:
  // 0 - Password OK
  // 1 - newPass and confirmPass dont match.
  // 2 - newPass too short
  // 3 - newPass doesnt contain numeric character
  // 4 - newPass doesnt contain uppercase letter
  let upperCase = false;
  let numeric = false;

  if (newPass.length <= 5) return 2;

  for (let i = 0; i < newPass.length; i++) {
    if (!isNaN(newPass[i])) numeric = true;
    else {
      if (newPass[i] === newPass[i].toUpperCase()) upperCase = true;
    }
  }
  if (!numeric) return 3;
  if (!upperCase) return 4;
  if (newPass !== confirmNewPass) return 1;
  return 0;
};

export const passwordIsExtraStrong = (pw) => {
  return /[~`!#$%^&*+=\-[\]\\';,/{}|":<>?]/g.test(pw);
};

export const validateEmail = (newEmail) => {
  // Valid email regex:
  // http://stackoverflow.com/questions/46155/validate-email-address-in-javascript
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(newEmail);
};

export const validatePaymentInformation = (paymentObj, t) => {
  let errors = {};
  if (paymentObj.cardID === -1) {
    for (let i = 0; i < paymentObj.cardholderName.length; i++) {
      if (paymentObj.cardholderName[i] !== ' ' && isFinite(Number(paymentObj.cardholderName[i]))) {
        errors.cardholderName = true;
        errors.message = t('buyModal_stepOne_error_cardholderName_no_numbers');
        return errors;
      }
    }

    if (paymentObj.cardholderName === '') {
      errors.cardholderName = true;
      errors.message = t('buyModal_stepOne_error_cardholderName_no_empty');
    } else if (paymentObj.cardholderRegno.length !== 10) {
      errors.cardholderRegno = true;
      errors.message = t('buyModal_stepOne_error_cardholderRegno');
    } else if (!isFinite(Number(paymentObj.cardholderRegno))) {
      errors.cardholderRegno = true;
      errors.message = t('buyModal_stepOne_error_cardholderRegno');
    } else if (getCreditCardType(paymentObj.cardNumber) === undefined) {
      errors.cardNumber = true;
      errors.message = t('buyModal_stepOne_error_cardNumber');
    } else if (paymentObj.validMonth.length !== 2) {
      errors.validMonth = true;
      errors.message = t('buyModal_stepOne_error_monthYear');
    } else if (!isFinite(Number(paymentObj.validMonth))) {
      errors.validMonth = true;
      errors.message = t('buyModal_stepOne_error_monthYear');
    } else if (paymentObj.validYear.length !== 4) {
      errors.validYear = true;
      errors.message = t('buyModal_stepOne_error_monthYear');
    } else if (!isFinite(Number(paymentObj.validYear))) {
      errors.validYear = true;
      errors.message = t('buyModal_stepOne_error_monthYear');
    }
  }
  if (errors.message) return errors;

  if (!validateCvc2(paymentObj)) {
    errors.cvc = true;
    errors.message = t('buyModal_stepOne_error_cvc');
  }
  return errors;
};

const validateCvc2 = (paymentObj) => {
  if (paymentObj.cvc2 === '') return false;
  if (paymentObj.cvc2.length !== 3) return false;
  if (!isFinite(Number(paymentObj.cvc2))) return false;
  return true;
};

export const getCreditCardType = (number) => {
  const visaRegex = /^4[0-9]{12}(?:[0-9]{3})?$/;
  if (visaRegex.test(number)) return 'visa';

  const mcRegex = /^5[1-5][0-9]{14}$/;
  if (mcRegex.test(number)) return 'mastercard';

  const amexRegex = /^3[47][0-9]{13}$/;
  if (amexRegex.test(number)) return 'amex';

  return undefined;
};

export const validateIcelandicPhoneNumber = (number) => {
  if (number.length !== 7) {
    return false;
  } else {
    for (let i = 0; i < number.length; ++i) {
      if (isNaN(number[i])) {
        return false;
      }
    }

    if (number[0] !== 5 && number[0] !== 6 && number[0] !== 7 && number[0] !== 8) {
      return false;
    }
  }
  return true;
};

/**
 * Checks if the varbit is correct
 * @param {String} str - string to be validated, assumed to be 10 digits
 * @param {Number} type - 0:=individual, 1:=company, 2:=both
 * @return {Boolean} isValidVarBit
 */
export const isValidVarBit = (str, type) => {
  let first = parseInt(str.substring(0, 1), 10);
  let second = parseInt(str.substring(1, 2), 10);
  let third = parseInt(str.substring(2, 3), 10);
  let fourth = parseInt(str.substring(3, 4), 10);
  let fifth = parseInt(str.substring(4, 5), 10);
  let sixth = parseInt(str.substring(5, 6), 10);
  let seventh = parseInt(str.substring(6, 7), 10);
  let eight = parseInt(str.substring(7, 8), 10);
  let varbit = parseInt(str.substring(8, 9), 10);

  if ((type === 0 && first > 3) || (type === 1 && first < 4)) {
    return false;
  }

  let calculatedVarBit =
    11 - ((3 * first + 2 * second + 7 * third + 6 * fourth + 5 * fifth + 4 * sixth + 3 * seventh + 2 * eight) % 11);

  if (calculatedVarBit === 11) {
    calculatedVarBit = 0;
  }

  return varbit === calculatedVarBit;
};

/**
 * Validate Icelandic Registry Numbers
 * @param {String} str - string to be validated, ten numeric letters
 * @param {Number} type - 0: individual, 1: company, 2: both
 * @return {Boolean} validateRegistryNumber
 */
export const validateRegistryNumber = (str, type) => {
  let re = new RegExp('[0-7]{1}[0-9]{8}(0|9|8)');

  /** Basic check **/
  if (!(str.length === 10 && re.test(str))) {
    return false;
  }

  /** Varbit Check **/
  return isValidVarBit(str, type);
};

/**
 * Return RegistryNumberType
 * @param {String} str - string to be validated, ten numeric letters
 * @return {Number} 0: invalid, 1: individual, 2: company
 */
export const getRegistryNumberType = (str) => {
  let re = new RegExp('[0-7]{1}[0-9]{8}(0|9|8)');

  /** Basic check **/
  if (!str || !(str.length === 10 && re.test(str) && isValidVarBit(str, 2))) {
    return 0;
  }

  /** Valid registryNumber, is it individual or a company **/
  return str.charAt(0) < 4 ? 1 : 2;
};

export const validatePropertyFixedNumber = (fixedNumber) => {
  if (fixedNumber.length !== 7 || fixedNumber[0] !== '2') {
    return false;
  } else {
    for (let i = 0; i < fixedNumber.length; ++i) {
      if (isNaN(fixedNumber[i])) {
        return false;
      }
    }
  }
  return true;
};

export const validatePhoneNumber = (number) => {
  if (number.length !== 7) {
    return false;
  } else {
    for (let i = 0; i < number.length; ++i) {
      if (isNaN(number[i])) {
        return false;
      }
    }
  }
  return true;
};
