import React, { useEffect, useState } from 'react';
import { useAuthContext } from 'jsx/auth/AuthProviderV2';
import { useTranslation } from 'jsx/hooks/useTranslation';
import { SigningStatus } from 'pages/Signing/SigningStatus';
import { apiDecision } from 'api/apiDecision';
import { Typography, CircularProgress, useMediaQuery } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { apiKYC } from 'api/apiKYC';

export const Signing = ({
  applicationID,
  currentStep,
  signingText,
  comingFrom,
  subjects,
  subscriber,
  confirmStep,
  trySigningAgain,
  guid,
}) => {
  const { user, forceAccessToken, setForce } = useAuthContext();
  const { t, loaded } = useTranslation(2026);
  const [isApproved, setIsApproved] = useState(null);
  const [isSigned, setIsSigned] = useState(null);
  const [signatureStatus, setSignatureStatus] = useState(null);
  const matches = useMediaQuery('(min-width:800px)');

  useEffect(() => {
    getStatus();
  }, []);

  useEffect(() => {
    if (isApproved) {
      forceTokenBeforeRun();
    }
  }, [isApproved]);

  useEffect(() => {
    if (isSigned) {
      forceTokenBeforeRun();
    }
  }, [isSigned]);

  const getStatus = () => {
    if (comingFrom === 'terms') {
      apiDecision.getApplicationSignatureStatusWaitTimeInSeconds(applicationID, 80).then((res) => {
        if (res && res.length !== 0 && !res.error) {
          setIsApproved(res[0].isApproved);
          if (!res[0].isApproved && res[0].approvalStatusId) {
            setSignatureStatus(res[0].approvalStatusId);
          }
        }
      });
    } else if (comingFrom === 'kyc') {
      apiKYC.checkSignatureStatus(guid, 80).then((res) => {
        if (res && res.length !== 0 && !res.error) {
          setIsSigned(res[0].isApproved);
          if (!res[0].isApproved && res[0].approvalStatusId) {
            setSignatureStatus(res[0].approvalStatusId);
          }
        }
      });
    } else {
      apiDecision.getContractSignatureStatusWaitTimeInSeconds(applicationID, 80).then((res) => {
        if (res && res.length !== 0 && !res.error) {
          setIsSigned(res[0].isApproved);
          if (!res[0].isApproved && res[0].approvalStatusId) {
            setSignatureStatus(res[0].approvalStatusId);
          }
        }
      });
    }
  };

  const forceTokenBeforeRun = async () => {
    setForce(true);
    await forceAccessToken();
    const request = {
      applicationID: applicationID,
      stepId: currentStep.stepId,
    };
    confirmStep(request);
  };

  if (!loaded) return null;
  return (
    <Grid container spacing={4} sx={{ paddingTop: '20px' }} alignItems={'center'}>
      <Grid xs={12} md={6}>
        <Grid container spacing={2}>
          <Grid xs={12}>
            <Typography variant="h3">{signingText.title}</Typography>
          </Grid>
          <Grid xs={12}>
            <Typography variant="subtitle1">{signingText.boldText}</Typography>
          </Grid>
          <Grid xs={12}>
            <Typography>{signingText.desc}</Typography>
          </Grid>
          <Grid xs={12}>
            {!signatureStatus && (
              <>
                <span className="text-semibold">
                  {user.name ? user.name : subjects.map((subject, index) => <span key={index}>{subject.name}</span>)}
                </span>{' '}
                <span className="text-gray" style={{ paddingRight: '20px' }}>
                  {t('pending_approval')}
                </span>
                <span>
                  <CircularProgress color="accent" size={20} />
                </span>
              </>
            )}
          </Grid>
          <Grid xs={12}>
            {signatureStatus && <SigningStatus status={signatureStatus} trySigningAgain={trySigningAgain} />}
          </Grid>
        </Grid>
      </Grid>
      <Grid xs={12} md={6} textAlign={'center'}>
        {matches && (
          <img
            src={`${process.env.REACT_APP_WEB_DECISION_THEME_IMG_URL}/decision/${subscriber.subscriberID}/signingPhone.svg`}
            height="150px"
            width="150px"
          />
        )}
      </Grid>
    </Grid>
  );
};
