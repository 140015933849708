import FileSaver from 'file-saver';
import axios from 'axios';
import 'json.date-extensions/json.date-extensions.js';

export const apiGetCall = (url, options = {}) => {
  return apiCall(url, options);
};

export const apiDeleteCall = (url, options = {}) => {
  let newOptions = { ...options, method: 'delete' };
  return apiCall(url, newOptions);
};

export const apiPutCall = (url, options = {}) => {
  let newOptions = { ...options, method: 'put' };
  return apiCall(url, newOptions);
};

export const apiPostCall = (url, body, options = {}) => {
  let newOptions = { ...options, method: 'post', data: body };
  return apiCall(url, newOptions);
};

export const isResultOk = (obj, notifyErrorhandler, single = false) => {
  if (obj != null && obj.error != undefined) {
    if (notifyErrorhandler) {
      if (single) {
        notifyErrorhandler(obj);
      } else {
        notifyErrorhandler([obj]);
      }
    }
    return false;
  } else if (obj == null) {
    return true;
  }
  return obj;
};

const useDetailError = process.env.REACT_APP_DETAILED_API_ERRORS;
const disableDateParse = process.env.REACT_APP_DISABLE_DATE_PARSE;

export const apiCall = (url, options = {}) => {
  const config = {
    url,
    method: options.method || 'get',
    responseType: options.responseType || 'json',
    headers: options.headers || {},
    transformResponse: [
      function (data) {
        if (data == undefined || data === '') {
          return undefined;
        } else if (data instanceof Blob) {
          return data;
        } else {
          try {
            if (typeof data === 'string' || data instanceof String) {
              return disableDateParse ? JSON.parse(data) : JSON.parseWithDate(data);
            } else {
              return disableDateParse ? JSON.parse(JSON.stringify(data)) : JSON.parseWithDate(JSON.stringify(data));
            }
          } catch (e) {
            return data;
          }
        }
      },
    ],
  };

  if (options.signal) {
    config.signal = options.signal;
  }
  if (options.cancelToken) {
    config.cancelToken = options.cancelToken;
  }

  config.headers['Content-Type'] = options.contentType || 'application/json';

  if (options.data) {
    if (options.formData) {
      let fd = new FormData();
      fd.append(options.fieldName || 'file', options.data);
      config.data = fd;
    } else {
      if (!options.contentType || config.headers['Content-Type'] === 'application/json') {
        config.data = JSON.stringify(options.data);
      } else {
        config.data = options.data;
      }
    }
  }

  return axios(config)
    .then((response) => {
      return response.data !== undefined ? response.data : response.status === 204 ? null : response;
    })
    .catch((e) => {
      // console.log("APICALL response", e);
      if (e.code === 'ERR_CANCELED' || axios.isCancel(e)) {
        return {
          status: -1,
          statusText: e.code,
          message: e.message,
        };
      }

      let error = {
        status: -1,
        statusText: 'No connection',
        message: '-1 (No connection)',
        detail: 'No connection available to url ' + config.url,
        description: '',
      };
      if (e.response) {
        error.status = e.response.status;
        error.statusText = e.response.statusText;
        if (e.response.status == 404) {
          error.message = '404 (Not found)';
          error.detail = 'Service was not found on url ' + config.url;
        } else if (e.response.status == 415) {
          error.message = '415 (Unsupported Media Type)';
          error.detail = 'Check the parameters values sent to url ' + config.url;
        }
      }
      if (e.response && e.response.data != null) {
        error.errorId = e.response.data.error_id;
        error.logGuid = e.response.data.logGuid;
        //Usally 400 Bad request
        let body = e.response.data;

        if (body != null && body.error) {
          error.message = body.error;
          error.detail = 'Service url ' + config.url;
          if (useDetailError === true) {
            error.detail = body.error_details;
          }
          error.description = body.error_description;
        } else if (body != null) {
          error.message = '400 (Bad request)';
          let text = JSON.stringify(body);
          error.detail = 'Service url ' + config.url + '\n' + text;
        }
      }
      // console.log("Error returned", error);
      return { error: error };
    });
};

export const fetchPDFBinary = (url, filename) => {
  apiCall(url).then((data) => {
    if (data !== undefined && data !== null) {
      let ab = base64ToArrayBuffer(data);
      let blob = new Blob([ab], { type: 'application/pdf' });
      FileSaver.saveAs(blob, `${filename}.pdf`);
    }
  });
};

export const dateToISO = (date) => {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();
  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;
  return [year, month, day].join('-');
};

export const isoToDate = (dateStr) => {
  return new Date(dateStr);
};

export const saveFile = (fileData, fileName, type) => {
  let ab = base64ToArrayBuffer(fileData);
  let blob = new Blob([ab], { type: type });
  FileSaver.saveAs(blob, fileName);
};

export const openPDFInBrowser = (data, fileName) => {
  let ab = base64ToArrayBuffer(data);
  let blob = new Blob([ab], { type: 'application/pdf' });
  var url = URL.createObjectURL(blob);
  if (fileName) {
    url += '#filename=' + encodeURIComponent(`${fileName}.pdf`);
  }

  window.open(url, '_blank');
  URL.revokeObjectURL(url);
};

export const base64ToArrayBuffer = (base64) => {
  let binaryString = window.atob(base64);
  let binaryLen = binaryString.length;
  let bytes = new Uint8Array(binaryLen);
  for (let i = 0; i < binaryLen; i++) {
    let ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }
  return bytes;
};
