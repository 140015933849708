import React from 'react';
import { ArrowStepper } from 'components/ArrowStepper';
import { Container, Paper, Typography, Stack, Button, CircularProgress } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

export const Frame = ({
  currentStep,
  trail,
  height,
  children,
  title,
  smallPadding,
  rightButtonProps,
  middleButtonProps,
  leftButtonProps,
  loading,
}) => {
  return (
    <Container fixed>
      <Grid container spacing={3}>
        <Grid xs={12}>
          <ArrowStepper currentStepTypeId={currentStep.stepTypeId} trail={trail} />
        </Grid>
        {title && (
          <Grid xs={12}>
            <Paper
              sx={{
                height: 'auto',
                padding: '24px',
                boxShadow: '0px 3px 3px -2px rgb(0 0 0 / 20%)',
                borderRadius: '8px',
                position: 'relative',
              }}
            >
              <Typography variant="h2">{title}</Typography>
            </Paper>
          </Grid>
        )}

        <Grid xs={12}>
          <Paper
            variant={'paperFrame'}
            sx={{ height: height ? height : 'auto', padding: smallPadding ? '10px' : '40px' }}
          >
            {children}
          </Paper>
        </Grid>
        <Grid xs={12} display={'flex'}>
          <Stack direction="row" margin={'auto'} spacing={2}>
            {leftButtonProps && (
              <Button variant="outlined" disabled={leftButtonProps.disabled} onClick={() => leftButtonProps.action()}>
                {leftButtonProps.text}
              </Button>
            )}
            {middleButtonProps && (
              <Button
                variant="contained"
                disabled={middleButtonProps.disabled}
                onClick={() => middleButtonProps.action()}
              >
                {middleButtonProps.text}
              </Button>
            )}
            {rightButtonProps && (
              <Button
                sx={{
                  '&:hover .MuiCircularProgress-root': {
                    color: 'white',
                  },
                }}
                variant="contained"
                disabled={rightButtonProps.disabled}
                onClick={() => rightButtonProps.action()}
              >
                {loading ? <CircularProgress color="accent" size={24} /> : rightButtonProps.text}
              </Button>
            )}
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
};
