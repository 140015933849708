import React, { useEffect, useState } from 'react';
import { apiDecision } from 'api/apiDecision';
import { Document, Page } from 'react-pdf';
import { useTranslation } from 'jsx/hooks/useTranslation';
import Grid from '@mui/material/Unstable_Grid2';
import { Button, CircularProgress, useMediaQuery } from '@mui/material';
import { Frame } from '../Frame';
import { AlertSnackbar } from '@creditinfo/react-common-lib';
import { Signing } from 'pages/Signing';

export const Contract = ({
  currentStep,
  applicationID,
  trail,
  subscriber,
  confirmStep,
  subjects,
  previousStep,
  cancelApplication,
}) => {
  const { t } = useTranslation(2026);
  const [status, setStatus] = useState('contract');
  const [signingText, setSigningText] = useState(null);
  const currentStepID = currentStep.stepId;
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [renderNavButtons, setRenderNavButtons] = useState(false);
  const [contractPDFDocument, setContractPDFDocument] = useState(null);
  const [loading, setLoading] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [skipSkignature, setSkipSignature] = useState(true);
  const [isSigningButtonDisabled, setIsSigningButtonDisabled] = useState(false);
  const matches = useMediaQuery('(min-width:800px)');

  useEffect(() => {
    createContract();
    if (currentStep?.validationRules?.length > 0) {
      setSkipSignature(
        currentStep.validationRules[0].validations.some((item) => {
          item.name === 'skipSignature' && item.value === true;
        })
      );
    }
  }, []);

  const createContract = () => {
    apiDecision.createContract(applicationID).then((res) => {
      if (res && !res.error) {
        setContractPDFDocument(res.contractPDF);
      }
    });
  };

  const requestPDFSigning = () => {
    setIsSigningButtonDisabled(true);
    setLoading(true);
    apiDecision.contractRequestPdfSigning(applicationID).then((res) => {
      if (res && !res.error) {
        startPDFSigning();
      } else if (res && res.error) {
        const request = {
          applicationID: applicationID,
          stepId: currentStepID,
        };
        confirmStep(request);
        setIsSigningButtonDisabled(false);
      }
    });
  };

  const startPDFSigning = () => {
    apiDecision.contractStartPdfSigning(applicationID).then((res) => {
      if (res && !res.error) {
        setLoading(false);
        setStatus('signing');
        setSigningText({
          title: t(`contract_signing_title_${subscriber.subscriberID}`),
          boldText: t(`contract_signing_desc1_${subscriber.subscriberID}`),
          desc: t(`contract_signing_desc2_${subscriber.subscriberID}`, res.controlCode),
        });
      } else if (res && res.error) {
        setStatus('contract');
        setErrorMessage(t(`contract_signing_failed_${subscriber.subscriberID}`));
      }
    });
  };

  const base64 = contractPDFDocument && `data:application/pdf;base64,${contractPDFDocument}`;

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setRenderNavButtons(true);
    onSuccess();
  }

  const onSuccess = () => {
    setPageNumber(1);
    setRenderNavButtons(true);
  };

  const changePage = (offset) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  };
  const previousPage = () => {
    changePage(-1);
  };
  const nextPage = () => {
    changePage(+1);
  };

  const trySigningAgain = () => {
    requestPDFSigning();
    setStatus('contract');
  };

  if (!contractPDFDocument)
    return (
      <Frame
        title={t(`contract_header_${subscriber?.subscriberID}_${currentStepID}`)}
        currentStep={currentStep}
        trail={trail}
        subscriber={subscriber}
        height={'361px'}
      >
        <Grid container spacing={2} textAlign={'center'} margin={matches ? '100px' : '50px'}>
          <Grid xs={12}>
            <CircularProgress color="accent" />
          </Grid>
        </Grid>
      </Frame>
    );

  return (
    <>
      {status === 'contract' && (
        <Frame
          currentStep={currentStep}
          trail={trail}
          title={t(`contract_header_${subscriber?.subscriberID}_${currentStepID}`)}
          smallPadding={!matches}
          rightButtonProps={{
            action: skipSkignature
              ? confirmStep({
                  applicationID: applicationID,
                  stepId: currentStepID,
                })
              : requestPDFSigning,
            text: t('sign'),
            disabled: isSigningButtonDisabled,
          }}
          middleButtonProps={
            currentStep?.stepOrchestration?.backingAllowed === false
              ? null
              : { action: previousStep, text: t('button_goBack') }
          }
          leftButtonProps={{ action: cancelApplication, text: t('cancel') }}
          loading={loading}
        >
          {errorMessage && <AlertSnackbar severity={'error'}>{errorMessage}</AlertSnackbar>}
          <Grid container spacing={matches ? 4 : 0} sx={{ paddingTop: '20px' }}>
            <Grid xs={12}>
              <Document
                file={base64}
                options={{ workerSrc: 'pdf.worker.js' }}
                onLoadSuccess={onDocumentLoadSuccess}
                scale={2.0}
              >
                <Page
                  height={matches ? 1100 : 400}
                  width={matches ? 1000 : 300}
                  renderMode="canvas"
                  size={'A4'}
                  pageNumber={pageNumber}
                ></Page>
              </Document>
              {renderNavButtons && (
                <Grid container>
                  <Grid xs={5}>
                    {t('page')} {pageNumber} {t('of')} {numPages}
                  </Grid>
                  <Grid xs={7}>
                    <Button disabled={pageNumber <= 1} onClick={previousPage}>
                      {'<'}
                    </Button>
                    <Button disabled={pageNumber === numPages} onClick={nextPage}>
                      {'>'}
                    </Button>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Frame>
      )}
      {status === 'signing' && (
        <Frame
          title={t(`contract_header_${subscriber?.subscriberID}_${currentStepID}`)}
          currentStep={currentStep}
          trail={trail}
          subscriber={subscriber}
        >
          <Signing
            applicationID={applicationID}
            currentStep={currentStep}
            signingText={signingText}
            comingFrom="contract"
            subjects={subjects}
            subscriber={subscriber}
            confirmStep={confirmStep}
            trySigningAgain={trySigningAgain}
          />
        </Frame>
      )}
    </>
  );
};
