import React, { useEffect, useState } from 'react';
import { apiDecision } from 'api/apiDecision';
import { useTranslation } from 'jsx/hooks/useTranslation';
import Grid from '@mui/material/Unstable_Grid2';
import { Typography, CircularProgress, useMediaQuery } from '@mui/material';
import { Frame } from 'pages/Frame';
import { useAuthContext } from 'jsx/auth/AuthProviderV2';
import { AlertSnackbar } from '@creditinfo/react-common-lib';
import { Signing } from 'pages/Signing';

export const Terms = ({
  currentStep,
  applicationID,
  trail,
  subscriber,
  confirmStep,
  subjects,
  cancelApplication,
  previousStep,
  loadingStep,
}) => {
  const { t } = useTranslation(2026);
  const [terms, setTerms] = useState(null);
  const [status, setStatus] = useState('terms');
  const { forceAccessToken, setForce } = useAuthContext();
  const currentStepID = currentStep.stepId;
  const toHtml = (htmlStr) => ({ __html: htmlStr });
  const [loading, setLoading] = useState(false);
  const [signingText, setSigningText] = useState(null);
  const [isApproved, setIsApproved] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [skipSignature, setSkipSignature] = useState(null);
  const matches = useMediaQuery('(min-width:800px)');

  useEffect(() => {
    apiDecision.getSigningTerms(applicationID, currentStepID).then((res) => {
      if (res && !res.error) {
        setTerms(res);
      }
    });
    if (currentStep?.validationRules?.length > 0) {
      setSkipSignature(
        currentStep.validationRules[0].parameters.some((item) => item.name === 'skipSignature' && item.value === true)
      );
    }
  }, []);

  useEffect(() => {
    if (isApproved) {
      forceTokenBeforeRun();
    }
  }, [isApproved]);

  const requestSigning = () => {
    setLoading(true);
    apiDecision.requestPdfSigning(applicationID, currentStepID).then((res) => {
      if (res && !res.error) {
        startSigning();
      }
    });
  };

  const startSigning = () => {
    apiDecision.startPdfSigning(applicationID).then((res) => {
      if (res && !res.error) {
        setLoading(false);
        setStatus('signing');
        setSigningText({
          title: t(`terms_sign_terms_title_${subscriber.subscriberID}`),
          boldText: t(`terms_sign_terms_desc1_${subscriber.subscriberID}`),
          desc: t(`terms_sign_terms_desc2_${subscriber.subscriberID}`, res.controlCode),
        });
      } else if (res && res.error) {
        if (res.error.error === '104') {
          setIsApproved(true);
        } else {
          setLoading(false);
          setStatus('terms');
          setErrorMessage(t(`terms_signing_failed_${subscriber.subscriberID}`));
        }
      }
    });
  };

  const forceTokenBeforeRun = async () => {
    setForce(true);
    await forceAccessToken();
    const request = {
      applicationID: applicationID,
      stepId: currentStepID,
    };
    confirmStep(request);
    setLoading(false);
  };

  const trySigningAgain = () => {
    requestSigning();
    setStatus('terms');
  };

  const skipSignatureAndConfirm = () => {
    const request = {
      applicationID: applicationID,
      stepId: currentStepID,
    };
    confirmStep(request);
  };
  if (loading)
    return (
      <Frame
        title={t(`terms_header_${subscriber?.subscriberID}_${currentStepID}`)}
        currentStep={currentStep}
        trail={trail}
        subscriber={subscriber}
      >
        <Grid container spacing={2} textAlign={'center'} margin={matches ? '100px' : '50px'}>
          <Grid xs={12}>
            <Typography>{t('please_wait_to_sign')}</Typography>
          </Grid>
          <Grid xs={12}>
            <CircularProgress color="accent" />
          </Grid>
        </Grid>
      </Frame>
    );

  return (
    <>
      {status === 'terms' && (
        <Frame
          currentStep={currentStep}
          trail={trail}
          subscriber={subscriber}
          title={t(`terms_header_${subscriber?.subscriberID}_${currentStepID}`)}
          leftButtonProps={{ text: t('cancel'), action: cancelApplication }}
          rightButtonProps={{
            text: t('accept'),
            action: skipSignature ? skipSignatureAndConfirm : requestSigning,
          }}
          middleButtonProps={
            currentStep?.stepOrchestration?.backingAllowed === false
              ? null
              : { action: previousStep, text: t('button_goBack') }
          }
          loading={loadingStep}
        >
          {errorMessage && <AlertSnackbar severity={'error'}>{errorMessage}</AlertSnackbar>}
          <Typography>
            <span dangerouslySetInnerHTML={toHtml(terms && terms.signingTerm)}></span>
          </Typography>
        </Frame>
      )}
      {status === 'signing' && (
        <Frame
          title={t(`terms_header_${subscriber?.subscriberID}_${currentStepID}`)}
          currentStep={currentStep}
          trail={trail}
          subscriber={subscriber}
        >
          <Signing
            applicationID={applicationID}
            currentStep={currentStep}
            signingText={signingText}
            comingFrom="terms"
            subjects={subjects}
            subscriber={subscriber}
            confirmStep={confirmStep}
            trySigningAgain={trySigningAgain}
          />
        </Frame>
      )}
    </>
  );
};
