import React, { useState } from 'react';
import { Typography, OutlinedInput, FormControl, InputAdornment, InputLabel } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

const formatNumber = (number) => {
  if (number) return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  else return '';
};

export const NumericalInput = ({ param, subscriber, t, validation, setValidation }) => {
  const [valueToDisplay, setValueToDisplay] = useState(formatNumber(param.value));

  const onChange = (value, param) => {
    const numericValue = value.replaceAll('.', '');
    if (!isNaN(numericValue)) {
      setValidation({
        ...validation,
        [param.subscriberParameterID]: null,
      });

      param.value = Number(numericValue);
      setValueToDisplay(formatNumber(numericValue));
    }
  };

  const onInputValidation = (numberInput, param) => {
    const input = numberInput.replaceAll('.', '');

    // Check for non-numeric input
    if (isNaN(input) || input === '') {
      setValidation({
        ...validation,
        [param.subscriberParameterID]: {
          type: 'error',
          message: t('userInputValidation_numerical_invalid'),
        },
      });
    } else if (parseInt(input) < parseInt(param.inputMin) || parseInt(input) > parseInt(param.inputMax)) {
      setValidation({
        ...validation,
        [param.subscriberParameterID]: {
          type: 'error',
          message: t(
            `userInputParameter_${subscriber.subscriberID}_${param.subscriberParameterID}_inputValidation_exceedsRange`,
            formatNumber(parseInt(param.inputMin)),
            formatNumber(parseInt(param.inputMax))
          ),
        },
      });
    }
  };

  return (
    <Grid xs={12} sx={{paddingLeft: param.dependency?.dependencyParameters?.length > 0 ? '40px' : ''}}>
      <Typography sx={{ marginBottom: '10px' }} variant="subtitle1">
      {t(`userInputParameter_${subscriber.subscriberID}_${param.subscriberParameterID}`)} 
      <span style={{color: 'red'}}>{param.isRequired ? ' *' : ''}</span>
      </Typography>
      <FormControl fullWidth error={validation[param.subscriberParameterID] ? true : false}>
        <InputLabel sx={{ padding: '2px', backgroundColor: 'white' }}>
          {validation[param.subscriberParameterID]?.message || ''}
        </InputLabel>
        <OutlinedInput
          variant="outlined"
          inputProps={{
            inputMode: 'numeric',
            pattern: '[0-9]*',
            min: param.inputMin,
            max: param.inputMax,
          }}
          sx={{ width: '100%' }}
          value={valueToDisplay}
          autoComplete="off"
          required
          onChange={(event) => onChange(event.target.value, param)}
          onBlur={(event) => onInputValidation(event.target.value, param)}
          endAdornment={
            (param.unit === 'kr' || param.unit === 'kr.') && (
              <InputAdornment position="start">{t('UNIT_ISK')}</InputAdornment>
            )
          }
        />
      </FormControl>
    </Grid>
  );
};
