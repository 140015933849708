import React, { useEffect, useState } from 'react';
import { Frame } from 'pages/Frame';
import { useTranslation } from 'jsx/hooks/useTranslation';
import { Questionnaire, getPropertyValueCaseInsensitive } from '@creditinfo/react-common-lib';
import { Box } from '@mui/material';
import { apiKYC } from 'api/apiKYC';
import { useAuthContext } from 'jsx/auth/AuthProviderV2';
import { Signing } from 'pages/Signing';
import { AlertSnackbar } from '@creditinfo/react-common-lib';
import { apiShared } from 'api/apiShared';
import { toast } from 'react-toastify';
import { CircularProgress, useMediaQuery } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

export const KnowYourCustomer = ({
  currentStep,
  applicationID,
  subscriber,
  trail,
  cancelApplication,
  subjects,
  confirmStep,
  previousStep,
}) => {
  const { t, loaded, lang } = useTranslation(2026);
  const { t: tKYC, loaded: tKYCLoaded } = useTranslation(2025);
  const [formData, setFormData] = useState(null);
  const [hasSigned, setHasSigned] = useState(false);
  const [loading, setLoading] = useState(null);
  const [status, setStatus] = useState('kyc');
  const [signingText, setSigningText] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const { forceAccessToken, setForce } = useAuthContext();
  const [allowConfirm, setAllowConfirm] = useState(false);
  const matches = useMediaQuery('(min-width:800px)');

  const currentStepID = currentStep.stepId;
  const digitalSignature =
    formData?.subscriberSettings &&
    getPropertyValueCaseInsensitive(formData?.subscriberSettings, 'kyC_DigitalSignature');
  const signingRequired = digitalSignature === 'true';

  const formEntryEditGuid = currentStep?.validationRules[0].parameters.find(
    (param) => param.name === 'KYC.User.FormEntryEditGuid'
  ).value;

  //To check if the user has completed signing the KYC form
  const kycUserCompleted = currentStep?.validationRules[0].validations.find(
    (param) => param.name === 'KYC.User.Completed'
  )?.valid;

  const getForm = () => {
    apiKYC.getForm(formEntryEditGuid).then((res) => {
      if (res && !res.error) {
        setFormData(res);
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    forceTokenBeforeRun();
  }, []);

  useEffect(() => {
    if (formEntryEditGuid) {
      setLoading(true);
      getForm();
    }
  }, [formEntryEditGuid]);

  useEffect(() => {
    if (kycUserCompleted) {
      setHasSigned(true);
    }
  }, [kycUserCompleted]);

  useEffect(() => {
    if (formData?.form) {
      setAllowConfirm(allFormItemsFilledOut(formData.form));
    }
  }, [formData?.form]);

  const onConfirm = () => {
    saveForm();
    const request = {
      applicationID: applicationID,
      stepId: currentStep.stepId,
    };
    confirmStep(request);
  };

  const forceTokenBeforeRun = async () => {
    setForce(true);
    await forceAccessToken();
  };

  const startSigning = () => {
    setLoading(true);

    const req = {
      guid: formEntryEditGuid,
      requestSignature: signingRequired,
      language: lang,
    };
    apiKYC.requestPDFSigning(req).then((res) => {
      if (res && res.error) {
        setStatus('kyc');
        setErrorMessage(t(`kyc_signing_failed_${subscriber.subscriberID}`));
      } else if (res && signingRequired) {
        setLoading(false);
        setStatus('signing');
        setSigningText({
          title: t(`kyc_sign_terms_title_${subscriber.subscriberID}`),
          boldText: t(`kyc_sign_terms_desc1_${subscriber.subscriberID}`),
          desc: t(`kyc_sign_terms_desc2_${subscriber.subscriberID}`, res.controlCode),
        });
      } else {
        onConfirm();
      }
      apiShared.updateFormStatus({
        editID: formEntryEditGuid ?? '',
        status: 2,
      });
    });
  };

  const trySigningAgain = () => {
    startSigning();
    setStatus('kyc');
  };

  const saveForm = () => {
    const formItemsToSave = {
      editID: formData?.form.editID,
      formItems: formData?.form.formItemsGrouped[0]?.formItems ?? [],
    };
    apiShared.saveForm(formItemsToSave).then((res) => {
      if (res.error) {
        toast.error(t('common_unsuccessful_action'));
      }
    });
  };

  const updateForm = (item) => {
    getUpdatedForm(formData, item);
  };

  const getUpdatedForm = (formData, item) => {
    const updatedFormItems = formData?.form.formItemsGrouped[0].formItems.map((formItem) => {
      if (formItem.formFieldID === item.formFieldID) {
        return {
          ...item,
          value: typeof item.value === 'string' ? item.value : JSON.stringify(item.value),
        };
      } else if (formItem.childFormItems) {
        const updatedChildFormItems = formItem.childFormItems.map((childFormItem) => {
          if (childFormItem.formFieldID === item.formFieldID) {
            return {
              ...item,
              value: typeof item.value === 'string' ? item.value : JSON.stringify(item.value),
            };
          }
          return childFormItem;
        });
        return { ...formItem, childFormItems: updatedChildFormItems };
      }
      return formItem;
    });

    const updatedForm = {
      ...formData,
      form: {
        ...formData?.form,
        formItemsGrouped: [{ ...formData?.form.formItemsGrouped[0], formItems: updatedFormItems }],
      },
    };
    setFormData(updatedForm);
  };

  const allFormItemsFilledOut = (form) => {
    if (!form?.formItemsGrouped) return false;
    for (const group of form.formItemsGrouped || []) {
      for (const item of group.formItems || []) {
        if (item.isRequired && !item.value) return false;

        if (item.childFormItems) {
          for (const childItem of item.childFormItems) {
            if (item.inputTypeID === 8) {
              // If multiselect then check if requiredParentFieldValue is in value array
              try {
                const valueArray = Array.isArray(item.value) ? item.value : JSON.parse(item.value);
                const translateString = item.options.find(
                  (option) => String(option.formFieldOptionID) === childItem.requiredParentFieldValue
                )?.option;
                const isInArray = valueArray.find((element) => element === tKYC(translateString)) != null;

                if (isInArray && !childItem.value) {
                  return false;
                }
              } catch (e) {
                return false;
              }
            } else if (childItem.requiredParentFieldValue === item.value && !childItem.value) {
              return false;
            }
          }
        }
        if (!item.value) {
          return false;
        }
      }
    }

    return true;
  };

  if (loading)
    return (
      <Frame currentStep={currentStep} trail={trail} subscriber={subscriber} height={'361px'}>
        <Grid container spacing={2} textAlign={'center'} margin={matches ? '100px' : '50px'}>
          <Grid xs={12}>
            <CircularProgress color="accent" />
          </Grid>
        </Grid>
      </Frame>
    );

  if (!loaded || !tKYCLoaded || !formData) return null;
  return (
    <>
      {status === 'kyc' && (
        <Frame
          currentStep={currentStep}
          trail={trail}
          subscriber={subscriber}
          title={t(`general_header_${subscriber.subscriberID}_${currentStepID}`)}
          leftButtonProps={{ action: cancelApplication, text: t('cancel') }}
          rightButtonProps={{
            action: hasSigned ? onConfirm : startSigning,
            text:
              hasSigned || !signingRequired
                ? t('button_confirm')
                : t(`button_sign_kyc_${subscriber.subscriberID}_${currentStepID}`),
            disabled: !allowConfirm,
          }}
          middleButtonProps={
            currentStep?.stepOrchestration?.backingAllowed === false
              ? null
              : { action: previousStep, text: t('button_goBack') }
          }
        >
          {errorMessage && <AlertSnackbar severity={'error'}>{errorMessage}</AlertSnackbar>}

          <Box>
            <Questionnaire
              form={formData?.form?.formItemsGrouped && JSON.parse(JSON.stringify(formData?.form?.formItemsGrouped))}
              onChange={saveForm}
              updateForm={updateForm}
              t={tKYC}
              labelText={formData?.subscriberCompanyName}
            />
          </Box>
        </Frame>
      )}

      {status === 'signing' && (
        <Frame currentStep={currentStep} trail={trail} subscriber={subscriber}>
          <Signing
            applicationID={applicationID}
            currentStep={currentStep}
            signingText={signingText}
            comingFrom="kyc"
            subjects={subjects}
            subscriber={subscriber}
            confirmStep={confirmStep}
            guid={formEntryEditGuid}
            trySigningAgain={trySigningAgain}
          />
        </Frame>
      )}
    </>
  );
};
