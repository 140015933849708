import React from 'react';
import { Typography, TextField, FormControl } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

export const TextInput = ({ param, subscriber, t, validation, setValidation }) => {
  const onChange = (value, param) => {
    setValidation({
      ...validation,
      [param.subscriberParameterID]: null,
    });
    param.value = value;
  };

  function validateIcelandicPhoneNumber(phoneNumber) {
    phoneNumber = phoneNumber.replace(/\D/g, '');
    const regex = /^(3|4|5|6|7|8|9)\d{6}$/;
    return regex.test(phoneNumber);
  }

  const onInputValidation = (input, param) => {
    if (input && param && param.type === 'PhoneNumber') {
      const inputWithoutSpace = input.replace(/\D/g, '');
      const valid = validateIcelandicPhoneNumber(inputWithoutSpace);

      if (!valid) {
        setValidation({
          ...validation,
          [param.subscriberParameterID]: {
            type: 'error',
            message: t('userInputValidation_phoneNumber_invalid'),
          },
        });
      } else {
        setValidation({
          ...validation,
          [param.subscriberParameterID]: null,
        });
      }
    }
  };

  return (
    <Grid xs={12} sx={{paddingLeft: param.dependency?.dependencyParameters?.length > 0 ? '40px' : ''}}>
      <Typography sx={{ marginBottom: '10px' }} variant="subtitle1">
      {t(`userInputParameter_${subscriber.subscriberID}_${param.subscriberParameterID}`)} 
      <span style={{color: 'red'}}>{param.isRequired ? ' *' : ''}</span>
      </Typography>
      <FormControl fullWidth>
        <TextField
          sx={{ width: '100%' }}
          value={param.value ? param.value : ''}
          variant="outlined"
          autoComplete="off"
          required
          error={validation[param.subscriberParameterID] ? true : false}
          label={validation[param.subscriberParameterID] ? validation[param.subscriberParameterID].message : ''}
          onChange={(event) => onChange(event.target.value, param)}
          inputProps={{ maxLength: param.type === 'PropertyFixedNumber' ? 8 : 50 }}
          onBlur={(event) => onInputValidation(event.target.value, param)}
        />
      </FormControl>
    </Grid>
  );
};
