import React, { useEffect, useState } from 'react';
import { useAuthContext } from 'jsx/auth/AuthProviderV2';
import { CircularProgress } from '@mui/material';
import { useTranslation } from 'jsx/hooks/useTranslation';
import Grid from '@mui/material/Unstable_Grid2';
import { Frame } from 'pages/Frame';
import { Typography, useMediaQuery } from '@mui/material';
import { AlertPanel } from '@creditinfo/react-common-lib';
import Section from 'jsx/elements/section/Section';

export const Decision = ({ currentStep, applicationID, confirmStep, cancelApplication, subscriber, trail }) => {
  const { forceAccessToken, setForce } = useAuthContext();
  const [decisionFailed, setDecisionFailed] = useState(false);
  const { t } = useTranslation(2026);
  const currentStepID = currentStep.stepId;
  const toHtml = (htmlStr) => ({ __html: htmlStr });
  const matches = useMediaQuery('(min-width:800px)');

  useEffect(() => {
    //If the step has never been validated, we confirm it. If it has been validated, we check if it has failed.
    if (
      currentStep.validationDate != null &&
      currentStep.validationRules &&
      currentStep.validationRules.length > 0 &&
      currentStep.validationRules[0].validations.some((item) => item.name === 'decisionCompleted' && !item.valid)
    ) {
      setDecisionFailed(true);
    } else {
      const request = {
        applicationID: applicationID,
        stepId: currentStepID,
      };
      confirmStep(request);
    }
  }, [currentStep]);

  const forceTokenBeforeRun = async () => {
    setForce(true);
    await forceAccessToken();
    const request = {
      applicationID: applicationID,
      stepId: currentStepID,
    };
    confirmStep(request);
  };

  if (decisionFailed) {
    return (
      <Frame
        currentStep={currentStep}
        trail={trail}
        subscriber={subscriber}
        currentStepID={currentStepID}
        rightButtonProps={{ action: forceTokenBeforeRun, text: t('try_again') }}
        leftButtonProps={{ action: cancelApplication, text: t('cancel') }}
      >
        <Grid container spacing={2} alignItems={'center'}>
          <Grid md={6} xs={12}>
            <AlertPanel severity={'warning'}>{t(`result_failed_title_${subscriber.subscriberID}`)}</AlertPanel>
            <Typography sx={{ paddingBottom: '10px' }}>
              <span dangerouslySetInnerHTML={toHtml(t(`result_failed_desc_${subscriber.subscriberID}`))} />
            </Typography>
          </Grid>
          {matches && (
            <Grid md={6} xs={12} sx={{ textAlign: 'right' }}>
              <img
                src={`${process.env.REACT_APP_WEB_DECISION_THEME_IMG_URL}/decision/${subscriber.subscriberID}/fileWarning.svg`}
                height="350px"
                width="350px"
              />
            </Grid>
          )}
        </Grid>
      </Frame>
    );
  } else {
    return (
      <Section>
        <Grid container spacing={2} textAlign={'center'} padding={'200px 0'}>
          <Grid xs={12}>
            <CircularProgress color="accent" />
          </Grid>
        </Grid>
      </Section>
    );
  }
};
