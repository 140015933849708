import React, { useEffect, useState } from 'react';
import { Typography, FormControl, Stack, InputLabel, TextField, CircularProgress } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { apiDecision } from 'api/apiDecision';

export const BankAccountInput = ({ param, subscriber, t, validation, setValidation, applicationID }) => {
  const [bankNr, setBankNr] = useState(param.value ? param.value.slice(0, 4) : null);
  const [ledger, setLedger] = useState(param.value ? param.value.slice(4, 6) : null);
  const [accountNr, setAccountNr] = useState(param.value ? param.value.slice(6, 12) : null);
  const [loading, setLoading] = useState();

  useEffect(() => {
    let combined = `${bankNr ? bankNr : ''}${ledger ? ledger : ''}${accountNr ? accountNr : ''}`;
    if (combined === '' || combined.length < 12) {
      setValidation({
        ...validation,
        [param.subscriberParameterID]: { type: 'notValidated' },
      });
    } else {
      validateBankAccount(combined);
    }
  }, []);

  const onChange = (value, section) => {
    let combined = '';
    if (section === 'bankNr') {
      combined = `${value}${ledger ? ledger : ''}${accountNr ? accountNr : ''}`;
      setBankNr(value.slice(0, 4));
    } else if (section === 'ledger') {
      combined = `${bankNr ? bankNr : ''}${value}${accountNr ? accountNr : ''}`;
      setLedger(value.slice(0, 2));
    } else if (section === 'accountNr') {
      combined = `${bankNr ? bankNr : ''}${ledger ? ledger : ''}${value}`;
      setAccountNr(value.slice(0, 6));
    }

    if (combined === '' || combined.length < 12) {
      setValidation({
        ...validation,
        [param.subscriberParameterID]: { type: 'notValidated' },
      });
    } else {
      validateBankAccount(combined);
    }

    param.value = combined;
  };

  const validateBankAccount = (account) => {
    const requestData = {
      bankNr: account.slice(0, 4),
      ledger: account.slice(4, 6),
      accountNr: account.slice(6, 12),
    };

    setLoading(true);
    setValidation({
      ...validation,
      [param.subscriberParameterID]: { type: 'pending', message: '' },
    });
    apiDecision.validateBankAccount(requestData, applicationID).then((res) => {
      if ((res && res === false) || !res) {
        setValidation({
          ...validation,
          [param.subscriberParameterID]: { type: 'error', message: t('bankAccountAndRegno_doNotMatch') },
        });
      } else {
        setValidation({
          ...validation,
          [param.subscriberParameterID]: null,
        });
      }
      setLoading(false);
    });
  };

  const onBankNrBlur = (value) => {
    const paddedValue = value.padStart(4, '0');
    onChange(paddedValue === '0000' ? '' : paddedValue.slice(0, 4), 'bankNr');
  };

  return (
    <Grid xs={12} sx={{paddingLeft: param.dependency?.dependencyParameters?.length > 0 ? '40px' : ''}}>
      <Typography sx={{ marginBottom: '10px' }} variant="subtitle1">
      {t(`userInputParameter_${subscriber.subscriberID}_${param.subscriberParameterID}`)} 
      <span style={{color: 'red'}}>{param.isRequired ? ' *' : ''}</span>
      </Typography>
      <FormControl fullWidth>
        <InputLabel
          variant="standard"
          sx={{ paddingTop: '10px' }}
          error={
            validation[param.subscriberParameterID] &&
            validation[param.subscriberParameterID].type !== 'pending' &&
            validation[param.subscriberParameterID].type !== 'notValidated'
              ? true
              : false
          }
        >
          {validation[param.subscriberParameterID] && validation[param.subscriberParameterID].message
            ? validation[param.subscriberParameterID].message
            : ''}
        </InputLabel>
        <Stack direction="row" spacing={2}>
          <TextField
            type="number"
            placeholder="0000"
            inputProps={{
              inputMode: 'numeric',
              pattern: '[0-9]*',
              maxLength: 4,
            }}
            sx={{
              width: '100%',
              '& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0,
              },
            }}
            value={bankNr}
            variant="standard"
            autoComplete="off"
            required
            error={
              validation[param.subscriberParameterID] &&
              validation[param.subscriberParameterID].type !== 'pending' &&
              validation[param.subscriberParameterID].type !== 'notValidated'
                ? true
                : false
            }
            onChange={(event) => onChange(event.target.value, 'bankNr')}
            onBlur={(event) => onBankNrBlur(event.target.value)}
          />
          <TextField
            type="number"
            placeholder="00"
            inputProps={{
              inputMode: 'numeric',
              pattern: '[0-9]*',
              maxLength: 2,
            }}
            sx={{
              width: '100%',
              '& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0,
              },
            }}
            value={ledger}
            variant="standard"
            autoComplete="off"
            required
            error={
              validation[param.subscriberParameterID] &&
              validation[param.subscriberParameterID].type !== 'pending' &&
              validation[param.subscriberParameterID].type !== 'notValidated'
                ? true
                : false
            }
            onChange={(event) => onChange(event.target.value, 'ledger')}
          />
          <TextField
            type="number"
            placeholder="000000"
            inputProps={{
              inputMode: 'numeric',
              pattern: '[0-9]*',
              maxLength: 6,
            }}
            sx={{
              width: '100%',
              '& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0,
              },
            }}
            value={accountNr}
            variant="standard"
            autoComplete="off"
            required
            error={
              validation[param.subscriberParameterID] &&
              validation[param.subscriberParameterID].type !== 'pending' &&
              validation[param.subscriberParameterID].type !== 'notValidated'
                ? true
                : false
            }
            onChange={(event) => onChange(event.target.value, 'accountNr')}
          />
          {loading && (
            <div>
              <CircularProgress color="accent" size={20} />
            </div>
          )}
        </Stack>
      </FormControl>
    </Grid>
  );
};
