import { useTranslation } from 'jsx/hooks/useTranslation';
import React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import { useMediaQuery } from '@mui/material';

export const ArrowStepper = ({ currentStepTypeId, trail }) => {
  const { t } = useTranslation(2026);
  const matches = useMediaQuery('(min-width:900px)');
  const CustomBreadcrumbs = styled(Breadcrumbs)({
    display: 'flex',
    listStyle: 'none',
    position: 'relative',
    justifyContent: 'right !important',
    alignItems: 'center',
    paddingTop: '20px',
    '@media (max-width: 600px)': {
      justifyContent: 'center !important',
    },
  });

  const CustomTypography = styled(Typography)(({ theme, complete, selected, isFirst, isLast }) => ({
    width: '170px',
    padding: '10px 10px 10px 30px',
    // backgroundColor: selected
    //   ? theme && theme.palette.accent && theme.palette.accent.light
    //   : complete
    //   ? theme.palette.accent && theme.palette.accent.main
    //   : '#f1f5f7',
    backgroundColor: complete
      ? theme.palette.accent && theme.palette.accent.main
      : selected
      ? theme && theme.palette.accent && theme.palette.accent.light
      : '#f1f5f7',
    fontWeight: selected ? '700' : '',
    borderRadius: isFirst ? '6px 0 0 6px' : isLast ? '0 6px 6px 0' : '0',
    margin: isFirst ? '0 0 0 0' : '0 0 0 -24px',
    clipPath: isFirst
      ? 'polygon(0% 0%, calc(100% - 20px) 0%, 100% 50%, calc(100% - 20px) 100%, 0% 100%)'
      : isLast
      ? 'polygon(20px 50%, 0% 0%, 100% 0%, 100% 100%, 0% 100%)'
      : 'polygon(20px 50%, 0% 0%, calc(100% - 20px) 0%, 100% 50%, calc(100% - 20px) 100%, 0% 100%)',
    '@media (max-width: 900px)': {
      maxWidth: '92px !important',
      padding: '9px 3px 8px 10px !important',
      textAlign: 'center',
      borderRadius: isFirst ? '6px 0 0 6px' : isLast ? '0 6px 6px 0' : '0',
      clipPath: isFirst
        ? 'polygon(0% 0%, calc(100% - 20px) 0%, 100% 50%, calc(100% - 20px) 100%, 0% 100%)'
        : isLast
        ? 'polygon(20px 50%, 0% 0%, 100% 0%, 100% 100%, 0% 100%)'
        : 'polygon(20px 50%, 0% 0%, calc(100% - 20px) 0%, 100% 50%, calc(100% - 20px) 100%, 0% 100%)',
    },
  }));

  return (
    <>
      {trail && trail.length !== 0 && (
        <CustomBreadcrumbs separator="">
          {trail.map((item, index, array) => {
            const isSelected = item.stepTypeID === currentStepTypeId;
            const isComplete =
              item.isConfirmed || (index === array.length - 1 && item.stepTypeID === currentStepTypeId);
            const isFirst = index === 0;
            const isLast = index === trail.length - 1;
            return (
              <CustomTypography
                key={index}
                complete={isComplete}
                selected={isSelected}
                isFirst={isFirst}
                isLast={isLast}
                className="custom-typography"
              >
                <Typography variant={matches ? 'subtitle1' : 'body3'}>
                  {!matches && t(`stepperItem_${item.stepTypeID}`).length > 5
                    ? t(`stepperItem_${item.stepTypeID}`).substring(0, 5) + '...'
                    : t(`stepperItem_${item.stepTypeID}`)}
                </Typography>
              </CustomTypography>
            );
          })}
        </CustomBreadcrumbs>
      )}
    </>
  );
};
