import React from 'react';

const Flex = ({
  justifyContent = 'flex-start',
  alignItems = 'flex-start',
  direction = 'row',
  style = {},
  onClick,
  className,
  children,
}) => {
  const stl = {
    display: 'flex',
    justifyContent,
    alignItems,
    flexDirection: direction,
    ...style,
  };
  return (
    <div style={stl} onClick={onClick} className={className}>
      {children}
    </div>
  );
};

export default Flex;
