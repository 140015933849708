import request from 'utils/api/apiRequest';
import { saveFile } from 'utils/api/apiCall';
const WEB_DECISION_API_URL = process.env.REACT_APP_DECISION_API_URL;
export const apiDecision = {
  //Application
  getSubscriber: (guid) => {
    return request(`${WEB_DECISION_API_URL}/Application/subscriber/${guid}`, {
      clientID: guid,
    });
  },
  getApplicationStatus: (req) => {
    return request(
      `${WEB_DECISION_API_URL}/Application/GetApplicationStatus/${req.guid}/${req.applicantSSN}/${req.applicantEmail}`,
      {
        guid: req.guid,
        applicantSSN: req.applicantSSN,
        applicantEmail: req.applicantEmail,
      }
    );
  },
  getApplicationStatusCompanyNotKnown: (guid) => {
    return request(`${WEB_DECISION_API_URL}/Application/GetApplicationStatus/${guid}`, {
      guid: guid,
    });
  },
  getSigningTerms: (applicationID, stepID) => {
    return request(`${WEB_DECISION_API_URL}/Application/SigningTerms/${applicationID}/${stepID}`, {
      applicationID: applicationID,
    });
  },
  requestPdfSigning: (applicationID, stepID) => {
    return request(`${WEB_DECISION_API_URL}/Application/requestPdfSigning/${applicationID}/${stepID}`, {
      method: 'post',
      data: applicationID,
    });
  },
  startPdfSigning: (applicationID) => {
    return request(`${WEB_DECISION_API_URL}/Application/startPdfSigning/${applicationID}`, {
      method: 'post',
      data: applicationID,
    });
  },
  getApplicationSignatureStatus: (applicationID) => {
    return request(`${WEB_DECISION_API_URL}/Application/signatureStatus/${applicationID}`, {
      applicationID: applicationID,
    });
  },
  getApplicationSignatureStatusWaitTimeInSeconds: (applicationID, waitTimeInSeconds) => {
    return request(`${WEB_DECISION_API_URL}/Application/signatureStatus/${applicationID}/${waitTimeInSeconds}`, {
      applicationID: applicationID,
      waitTimeInSeconds: waitTimeInSeconds,
    });
  },
  getContractSignatureStatusWaitTimeInSeconds: (applicationID, waitTimeInSeconds) => {
    return request(
      `${WEB_DECISION_API_URL}/Application/contract/signatureStatus/${applicationID}/${waitTimeInSeconds}`,
      {
        applicationID: applicationID,
        waitTimeInSeconds: waitTimeInSeconds,
      }
    );
  },
  validateStep: (req) => {
    return request(`${WEB_DECISION_API_URL}/Application/validation/validateStep`, {
      method: 'post',
      data: req,
    });
  },
  confirmStep: (req) => {
    return request(`${WEB_DECISION_API_URL}/Application/validation/confirmStep`, {
      method: 'post',
      data: req,
    });
  },
  getNewLoan: (applicationID) => {
    return request(`${WEB_DECISION_API_URL}/Application/newloan/get/${applicationID}`, {
      applicationID: applicationID,
    });
  },
  saveNewLoan: (req) => {
    return request(`${WEB_DECISION_API_URL}/Application/newloan/save`, {
      method: 'post',
      data: req,
    });
  },
  calculateNewLoan: (req) => {
    return request(`${WEB_DECISION_API_URL}/Application/newloan/calculate`, {
      method: 'post',
      data: req,
    });
  },
  getContract: (applicationID) => {
    return request(`${WEB_DECISION_API_URL}/Application/contract/get/${applicationID}`, applicationID);
  },
  createContract: (applicationID) => {
    return request(`${WEB_DECISION_API_URL}/Application/contract/create/${applicationID}`, {
      method: 'post',
      data: applicationID,
    });
  },
  contractRequestPdfSigning: (applicationID) => {
    return request(`${WEB_DECISION_API_URL}/Application/contract/requestPdfSigning/${applicationID}`, {
      method: 'post',
      data: applicationID,
    });
  },
  contractStartPdfSigning: (applicationID) => {
    return request(`${WEB_DECISION_API_URL}/Application/contract/startPdfSigning/${applicationID}`, {
      method: 'post',
      data: applicationID,
    });
  },
  cancelApplication: (applicationID) => {
    return request(`${WEB_DECISION_API_URL}/Application/CancelApplication/${applicationID}`, {
      method: 'post',
      data: applicationID,
    });
  },
  getAvailableCompanies: (applicationID) => {
    return request(`${WEB_DECISION_API_URL}/Application/availableCompanies/${applicationID}`, applicationID);
  },
  selectApplicationApplicant: (req) => {
    return request(
      `${WEB_DECISION_API_URL}/Application/ApplicationApplicant/${req.applicationID}/${req.applicantSSN}/${req.applicantEmail}`,
      {
        method: 'post',
        applicationID: req.applicationID,
        applicantSSN: req.applicantSSN,
        applicantEmail: req.applicantEmail,
      }
    );
  },
  saveUserInputParameters: (req) => {
    return request(`${WEB_DECISION_API_URL}/Application/UserInputParameters/save`, {
      method: 'post',
      data: req,
    });
  },
  previousStep: (applicationID, stepId) => {
    return request(`${WEB_DECISION_API_URL}/Application/previousStep`, {
      method: 'post',
      data: { applicationID, stepId },
    });
  },
  getAttachments: (applicationID, stepID) => {
    return request(`${WEB_DECISION_API_URL}/Application/${applicationID}/attachment/documents/${stepID}`);
  },
  getAttachment: (applicationID, attachmentDocumentID) => {
    request(`${WEB_DECISION_API_URL}/Application/${applicationID}/attachment/document/${attachmentDocumentID}`).then(
      (res) => {
        saveFile(res.content, `${res.fileName}.pdf`, res.contentType);
      }
    );
  },
  addAttachment: (applicationID, attachment) => {
    return request(`${WEB_DECISION_API_URL}/Application/${applicationID}/attachment/document`, {
      method: 'post',
      data: attachment,
    });
  },
  deleteAttachment: (applicationID, attachmentDocumentID) => {
    return request(`${WEB_DECISION_API_URL}/Application/${applicationID}/attachment/document/${attachmentDocumentID}`, {
      method: 'delete',
    });
  },
  validateBankAccount: (data, applicationID) => {
    return request(`${WEB_DECISION_API_URL}/Application/${applicationID}/bankAccount/validate`, {
      method: 'post',
      data: data,
    });
  },
  suggestCompanies: (query) => {
    return request(`${WEB_DECISION_API_URL}/Subject/Search/Suggestion/Companies`, {
      data: {
        query: query,
        orderBy: [],
        restrictions: [
          {
            constraint: 'isindividual',
            command: 'false',
          },
        ],
        filter: [],
        aggregations: true,
        pageSize: 8,
        pageNumber: 0,
      },
      method: 'post',
    });
  },
};

if (process.env.NODE_ENV === 'development') {
  window.apiDecision = apiDecision;
}
