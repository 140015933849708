import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { HelmetProvider } from 'react-helmet-async';
import 'utils/polyfills';
import React from 'react';
import * as Sentry from '@sentry/react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import LanguageProvider from 'jsx/locale/LanguageProviderV2';
import AuthProvider from 'jsx/auth/AuthProviderV2';
import ScrollToTop from 'jsx/layout/ScrollToTopV2';
import './main.less';
import { App } from './App';
import { CookieProvider } from 'jsx/auth/CookieProvider';
import ReactGA from 'react-ga';
import CustomThemeProvider from './CustomThemeProvider';
const history = createBrowserHistory();
import '@fontsource/open-sans/400.css';
import '@fontsource/open-sans/500.css';
import '@fontsource/open-sans/600.css';

if (process.env.REACT_APP_USE_ANALYTICS === 'yes') {
  ReactGA.initialize('UA-56354358-3', { debug: false, titleCase: false });
  history.listen((location) => ReactGA.pageview(location.pathname + location.search));
}

if (process.env.REACT_APP_SENTRY_LOGGING === 'yes') {
  Sentry.init({
    dsn: 'https://159e3de0276b41bbb7bcda419ee80fc2@o418448.ingest.sentry.io/4505278932189184',
    environment: process.env.REACT_APP_ENVIRONMENT,
    release: process.env.REACT_APP_GIT_COMMIT_HASH,
  });
}

const POSTLOGOUT_URL = process.env.REACT_APP_STS_POSTLOGOUT_URL.replace(/\/$/, '');

const authConfig = {
  authUrl: process.env.REACT_APP_STS_URL,
  clientId: 'WebDecision',
  scope: 'openid profile sharedapi creditinfo offline_access internalapi webapi',
  website: 'Decision',
  secret: 'MJ30bcMCdXVdOB4X8vdshNY7rw7kp90bc6oPXRbWWcVCNCXrLz',
  postLogoutRedirectUri: POSTLOGOUT_URL,
};

const container = document.getElementById('root');
const root = createRoot(container);
const queryClient = new QueryClient();

root.render(
  <HelmetProvider>
    <QueryClientProvider client={queryClient}>
      <AuthProvider authConfig={authConfig} history={history}>
        <CookieProvider>
          <CustomThemeProvider>
            <Router history={history}>
              <LanguageProvider>
                <ScrollToTop />
                <App />
              </LanguageProvider>
            </Router>
          </CustomThemeProvider>
        </CookieProvider>
      </AuthProvider>
    </QueryClientProvider>
  </HelmetProvider>
);
