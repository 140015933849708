import React from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';

const RModal = ({ isOpen, children, onClose }) => {
  const customStyles = {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.25)',
      zIndex: 1000
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      maxWidth: '600px',
      width: '90%'
    }
  };

  return (
    <ReactModal
      style={customStyles}
      closeTimeoutMS={300}
      isOpen={isOpen}
      contentLabel="Modal"
      onRequestClose={onClose}
      parentSelector={() => {
        const mainWrap = document.getElementById('main-wrap');
        if (mainWrap) return mainWrap;
        return document.body;
      }}
    >
      {children}
    </ReactModal>
  );
};

RModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func
};

export default RModal;
