import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { useTheme, Typography, Button, Container, Tooltip, Stack, useMediaQuery } from '@mui/material';
import { apiDecision } from 'api/apiDecision';
import { useTranslation } from 'jsx/hooks/useTranslation';

export const ApplicationApplicant = ({ currentStep, applicationID, subscriber, confirmStep, cancelApplication }) => {
  const { t, loaded } = useTranslation(2026);
  const currentStepID = currentStep.stepId;
  const [companies, setCompanies] = useState(null);
  const theme = useTheme();
  const matches = useMediaQuery('(min-width:800px)');

  useEffect(() => {
    if (applicationID) {
      getCompanies();
    }
  }, []);

  const getCompanies = () => {
    apiDecision.getAvailableCompanies(applicationID).then((res) => {
      if (res && !res.error) {
        setCompanies(res);
      }
    });
  };
  const onSelectApplicationApplicant = (regno) => {
    const req = {
      applicationID: applicationID,
      applicantSSN: regno,
      applicantEmail: null,
    };

    apiDecision.selectApplicationApplicant(req).then((res) => {
      if (res && !res.error) {
        const request = {
          applicationID: applicationID,
          stepId: currentStepID,
        };
        confirmStep(request);
      }
    });
  };

  if (!loaded) return null;
  return (
    <>
      <Container disableGutters maxWidth="xl">
        <Grid container sx={{ paddingTop: '60px' }}>
          <Grid xs={12} md={4}>
            <Grid container sx={{ padding: 5 }}>
              {companies && companies.length === 0 && (
                <Grid xs={12}>
                  <Grid container spacing={10}>
                    <Grid xs={12} sx={{ paddingBottom: '170px' }}>
                      <Typography variant="h6" component="div">
                        {t(`applicationApplicant_noCompanies_${subscriber.subscriberID}`)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              )}

              {companies && companies.length !== 0 && (
                <Grid xs={12}>
                  <Grid container spacing={2} justifyContent={'space-between'}>
                    <Stack spacing={3} sx={{ paddingBottom: '60px' }}>
                      <Typography variant="h2">{t(`applicationApplicant_title_${subscriber.subscriberID}`)}</Typography>
                      <Typography variant="h6">{t(`applicationApplicant_desc_${subscriber.subscriberID}`)}</Typography>
                    </Stack>
                    <Grid xs={12}>
                      <Stack spacing={3}>
                        {companies.map((company, index) => {
                          return (
                            <Button
                              variant="selectionPanel"
                              color="primary"
                              key={index}
                              sx={{
                                minWidth: matches ? 395 : 320,
                                maxWidth: 600,
                                borderColor: `${theme && theme.palette.accent && theme.palette.accent.main}`,
                                opacity: company.createApplicationProhibited ? '30%' : '',
                                '&:hover': {
                                  borderColor: `${theme && theme.palette.accent && theme.palette.accent.contrastText}`,
                                },
                                textAlign: 'left',
                              }}
                              onClick={
                                !company.createApplicationProhibited
                                  ? () => onSelectApplicationApplicant(company.companyRegno)
                                  : undefined
                              }
                            >
                              <Tooltip
                                placement="top"
                                title={
                                  company.createApplicationProhibited
                                    ? t(`applicationApplicant_createApplicationProhibited_${subscriber.subscriberID}`)
                                    : ''
                                }
                              >
                                <Grid container spacing={2} alignItems={'center'}>
                                  <Grid sx={{ padding: matches ? '20px' : '10px' }}>
                                    {matches && (
                                      <img
                                        className="icon-svg decision-icon-svg"
                                        src={`${process.env.REACT_APP_WEB_DECISION_THEME_IMG_URL}/decision/${subscriber.subscriberID}/building.svg`}
                                        color="blue"
                                        alt="icon"
                                        height={30}
                                        width={30}
                                      />
                                    )}
                                  </Grid>
                                  <Grid>
                                    <Typography variant={matches ? 'h5' : 'subtitle1'}>
                                      {company.companyName}
                                    </Typography>
                                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                      {company.involvements.map((involvement, index) => {
                                        if (index !== company.involvements.length - 1) {
                                          return (
                                            <span key={index}>
                                              {involvement.involvementType.involvementTypeName + ', '}{' '}
                                            </span>
                                          );
                                        } else
                                          return (
                                            <span key={index}>{involvement.involvementType.involvementTypeName}</span>
                                          );
                                      })}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Tooltip>
                            </Button>
                          );
                        })}
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Grid xs={12} sx={{ padding: 5 }}>
              <Button variant="contained" onClick={() => cancelApplication()}>
                {t('cancel')}
              </Button>
            </Grid>
          </Grid>

          <Grid xs={0} md={8}>
            <img
              src={`${process.env.REACT_APP_WEB_DECISION_THEME_IMG_URL}/decision/${subscriber.subscriberID}/selectCompany.svg`}
              alt="bg"
              style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', width: '70%' }}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
