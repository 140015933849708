import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import BusinessIcon from '@mui/icons-material/Business';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import { debounce } from '@mui/material/utils';
import { apiDecision } from 'api/apiDecision';

export const CorporateRegnoInput = ({ param, subscriber, t, validation, setValidation }) => {
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);

  const fetch = React.useMemo(
    () =>
      debounce((request, callback) => {
        apiDecision.suggestCompanies(request.inputValue).then((res) => callback(res));
      }, 400),
    []
  );

  React.useEffect(() => {
    if (param.value) {
      apiDecision
        .suggestCompanies(param.value)
        .then((res) => setValue(res && res.length > 0 ? res[0].source?.subjectName : null));
    }
  }, []);

  React.useEffect(() => {
    let active = true;
    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ inputValue }, (results) => {
      if (active) {
        let newOptions = [];
        if (value) {
          newOptions = [value];
        }
        if (results) {
          newOptions = [...newOptions, ...results];
        }
        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  return (
    <Grid xs={12} sx={{paddingLeft: param.dependency?.dependencyParameters?.length > 0 ? '40px' : ''}}>
      <Typography sx={{ marginBottom: '10px' }} variant="subtitle1">
      {t(`userInputParameter_${subscriber.subscriberID}_${param.subscriberParameterID}`)} 
      <span style={{color: 'red'}}>{param.isRequired ? ' *' : ''}</span>
      </Typography>
      <FormControl fullWidth>
        <Autocomplete
          sx={{ width: '100%' }}
          getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
          filterOptions={(x) => x}
          options={options}
          autoComplete
          autoHighlight
          includeInputInList
          filterSelectedOptions
          value={value}
          freeSolo
          noOptionsText="Ekkert fyrirtæki fannst"
          onChange={(event, newValue) => {
            setOptions(newValue ? [newValue, ...options] : options);
            setValue(newValue?.source?.subjectName);
            setValidation({
              ...validation,
              [param.subscriberParameterID]: null,
            });
            param.value = newValue?.source?.regno;
          }}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          renderInput={(params) => (
            <TextField
              error={validation[param.subscriberParameterID] ? true : false}
              {...params}
              label={
                validation[param.subscriberParameterID]
                  ? validation[param.subscriberParameterID]
                  : t(`userInputParameter_placeholder_${subscriber.subscriberID}_${param.subscriberParameterID}`)
              }
              fullWidth
            />
          )}
          renderOption={(props, option) => {
            return (
              <li {...props}>
                <Grid container alignItems="center">
                  <Grid item sx={{ display: 'flex', width: 44 }}>
                    <BusinessIcon sx={{ color: 'text.secondary' }} />
                  </Grid>
                  <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                    <Box
                      component="span"
                      dangerouslySetInnerHTML={{ __html: option?.highlights?.at(0)?.highlights?.at(0) }}
                    />
                    <Box component="span" sx={{ float: 'right' }}>
                      {isNaN(Number(inputValue)) ? option?.source?.regno : option?.source?.subjectName}
                    </Box>
                  </Grid>
                </Grid>
              </li>
            );
          }}
        />
      </FormControl>
    </Grid>
  );
};
