import React from 'react';
import { Typography, Input, FormControl, InputLabel } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { validateRegistryNumber } from 'utils/validators';

export const RegnoInput = ({ param, subscriber, t, validation, setValidation }) => {
  const onChange = (value, param) => {
    setValidation({
      ...validation,
      [param.subscriberParameterID]: null,
    });
    param.value = value;
  };
  const onInputValidation = (input, param) => {
    if (input) {
      if (!validateRegistryNumber(input)) {
        setValidation({
          ...validation,
          [param.subscriberParameterID]: { type: 'error', message: t('input_error_regno_invalid') },
        });
      } else {
        setValidation({
          ...validation,
          [param.subscriberParameterID]: null,
        });
      }
    }
  };
  return (
    <Grid xs={12} sx={{paddingLeft: param.dependency?.dependencyParameters?.length > 0 ? '40px' : ''}}>
      <Typography sx={{ marginBottom: '10px' }} variant="subtitle1">
      {`${t(`userInputParameter_${subscriber.subscriberID}_${param.subscriberParameterID}`)} ${
          param.isRequired ? '*' : ''
        }`}
      </Typography>
      <FormControl variant="standard" fullWidth>
        <InputLabel error={validation[param.subscriberParameterID] ? true : false}>
          {validation[param.subscriberParameterID] && validation[param.subscriberParameterID].message
            ? validation[param.subscriberParameterID].message
            : ''}
        </InputLabel>
        <Input
          type="number"
          inputProps={{
            inputMode: 'numeric',
            pattern: '[0-9]*',
            maxLength: 10,
          }}
          sx={{ width: '100%' }}
          value={param.value ? param.value : ''}
          variant="outlined"
          autoComplete="off"
          required
          error={validation[param.subscriberParameterID] ? true : false}
          onChange={(event) => onChange(event.target.value, param)}
          onBlur={(event) => onInputValidation(event.target.value, param)}
        />
      </FormControl>
    </Grid>
  );
};
