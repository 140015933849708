import React from 'react';
import { Typography, Slider, FormControl } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

export const SliderInput = ({ param, subscriber, t, validation, setValidation }) => {
  const onChange = (value, param) => {
    setValidation({
      ...validation,
      [param.subscriberParameterID]: null,
    });
    param.value = value;
  };
  return (
    <Grid xs={12} sx={{paddingLeft: param.dependency?.dependencyParameters?.length > 0 ? '40px' : ''}}>
      <Typography sx={{ marginBottom: '10px' }} variant="subtitle1">
      {t(`userInputParameter_${subscriber.subscriberID}_${param.subscriberParameterID}`)} 
      <span style={{color: 'red'}}>{param.isRequired ? ' *' : ''}</span>
      </Typography>
      <FormControl fullWidth>
        <Slider
          value={param.value === null ? 0 : param.value}
          max={parseInt(param.inputMax)}
          min={parseInt(param.inputMin)}
          aria-label="Default"
          valueLabelDisplay="auto"
          valueLabelFormat={(value) =>
            param.unit === 'ár'
              ? `${value} ${t('year')}`
              : `${value} ${value.toString().endsWith('1') ? t('month') : t('months')}`
          }
          onChange={(event, newValue) => onChange(newValue, param)}
        />
      </FormControl>
    </Grid>
  );
};
