import React, { useContext, useEffect, useState } from 'react';
import { Root } from 'jsx/layout';
import { useRemoveInitApp, useTranslationApi } from 'jsx/hooks';
import { ToastContainer } from 'react-toastify';
import { Helmet } from 'react-helmet';
import { useAuthContext, getLocationData } from 'jsx/auth/AuthProviderV2';
import { apiDecision } from 'api/apiDecision';
import { CustomThemeContext } from 'CustomThemeProvider';
import { Application } from 'pages/Application';
import { ErrorBoundary } from '@sentry/react';
import { ErrorPage } from '@creditinfo/react-common-lib';
import { Header } from 'components/Header/Header';
import 'react-toastify/dist/ReactToastify.css';

export function App() {
  const loaded = useTranslationApi(2026);
  const [subscriber, setSubscriber] = useState(null);
  const { setTheme } = useContext(CustomThemeContext);
  const currentUrl = window.location.href;
  const url = new URL(currentUrl);
  const { isAuthenticated, signIn } = useAuthContext();

  const searchParams = {
    clientID: url.searchParams.get('clientID'),
    applicantRegno: url.searchParams.get('applicantSSN'),
    applicantEmail: url.searchParams.get('applicantEmail'),
  };
  const themeInfo = {
    3326: {
      themeRedirectUrl: 'https://origo.is',
      themeEmail: 'info@origo.is',
      themePhoneNumber: '+354 516 1000',
    },
    3622: {
      themeRedirectUrl: 'https://arion.is',
      themeEmail: 'info@arion.is',
      themePhoneNumber: '+354 516 1000',
    },
    10806: {
      themeRedirectUrl: 'https://blikk.tech',
      themeEmail: 'hallo@blikk.tech',
      themePhoneNumber: '+354 862 3233',
    },
  };
  const [themeSubscriberInfo, setThemeSubscriberInfo] = useState({
    themeId: null,
    themeLink: null,
    themeLogo: null,
    themeBodyClass: null,
  });

  useEffect(() => {
    if (!isAuthenticated) return;
    if (searchParams.clientID) {
      apiDecision.getSubscriber(searchParams.clientID).then((res) => {
        if (res !== null && !res.error) {
          setSubscriber(res);
          const logo = `${process.env.REACT_APP_WEB_DECISION_THEME_IMG_URL}/Decision/logo/${res.subscriberID}.png`;
          const themeLink = `./styles/decision-theme-${res.subscriberID}.css`;
          const themeClass = `theme-${res.subscriberID}`;
          setThemeSubscriberInfo({
            themeId: res.subscriberID,
            themeLink: themeLink,
            themeLogo: logo,
            themeClass: themeClass,
            themeRedirectUrl: themeInfo[res.subscriberID].themeRedirectUrl,
            themeEmail: themeInfo[res.subscriberID].themeEmail,
          });

          if (res.subscriberID) {
            setTheme(`theme${res.subscriberID}`, true);
          } else {
            setTheme('creditInfo');
          }
        }
      });
    }
  }, [isAuthenticated, searchParams && searchParams.clientID]);

  useEffect(() => {
    if (subscriber && subscriber.subscriberID !== null) {
      // Load the theme file dynamically based on the ID received from the API
      import(`./styles/decision-theme-${subscriber.subscriberID}.less`);
    }
  }, [subscriber && subscriber.subscriberID]);

  useRemoveInitApp(true);
  useEffect(() => {
    if (!isAuthenticated) {
      signIn(getLocationData());
    }
  }, []);
  if (!loaded || !subscriber) return null;
  return (
    <>
      <Helmet>
        <title>{`Umsókn ${subscriber && subscriber.name ? subscriber.name : ''}`}</title>
        <link
          rel="icon"
          type="image/svg+xml"
          href={`${process.env.REACT_APP_WEB_DECISION_THEME_IMG_URL}/decision/${
            subscriber && subscriber.subscriberID
          }/favicon.svg`}
        />
        {themeSubscriberInfo && themeSubscriberInfo.themeClass && (
          <html lang="en" className={`${themeSubscriberInfo && themeSubscriberInfo.themeClass}`} />
        )}
      </Helmet>
      <Root>
        <ErrorBoundary fallback={<ErrorPage />} showDialog="false">
          {isAuthenticated && (
            <>
              <Header
                src={themeSubscriberInfo && themeSubscriberInfo.themeLogo}
                subscriber={subscriber && subscriber}
              />
              <ToastContainer />
              <Application applicationStatusRequest={searchParams} subscriber={subscriber} />
            </>
          )}
        </ErrorBoundary>
      </Root>
    </>
  );
}
