import React from 'react';
import { Typography, TextField, FormControl } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

export const EmailInput = ({ param, subscriber, t, validation, setValidation }) => {
  const onChange = (value, param) => {
    setValidation({
      ...validation,
      [param.subscriberParameterID]: { valid: true },
    });
    param.value = value;
  };

  // Input element validation.
  const onInputValidation = (input, param) => {
    if (input) {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const valid = re.test(input);
      if (!valid) {
        setValidation({
          ...validation,
          [param.subscriberParameterID]: {
            type: 'error',
            message: t('userInputValidation_email_invalid'),
          },
        });
      } else {
        setValidation({
          ...validation,
          [param.subscriberParameterID]: null,
        });
      }
    }
  };
  return (
    <Grid xs={12} sx={{paddingLeft: param.dependency?.dependencyParameters?.length > 0 ? '40px' : ''}}>
      <Typography sx={{ marginBottom: '10px' }} variant="subtitle1">
      {t(`userInputParameter_${subscriber.subscriberID}_${param.subscriberParameterID}`)} 
      <span style={{color: 'red'}}>{param.isRequired ? ' *' : ''}</span>
      </Typography>
      <FormControl fullWidth>
        <TextField
          sx={{ width: '100%' }}
          value={param.value ? param.value : ''}
          variant="outlined"
          autoComplete="off"
          required
          error={validation[param.subscriberParameterID] ? true : false}
          label={
            validation[param.subscriberParameterID]?.message ? validation[param.subscriberParameterID].message : ''
          }
          onChange={(event) => onChange(event.target.value, param)}
          onBlur={(event) => onInputValidation(event.target.value, param)}
        />
      </FormControl>
    </Grid>
  );
};
