import React from 'react';
import PropTypes from 'prop-types';

const Checkbox = ({ id, name, value, onChange, label, disabled, ...rest }) => {
  const handleChange = (e) => {
    onChange(name, e.target.checked);
  };
  return (
    <div className="__checkbox">
      <input
        id={id}
        type="checkbox"
        name={name}
        value={value}
        onChange={handleChange}
        checked={value}
        disabled={disabled}
        {...rest}
      />
      <label htmlFor={name}>{label}</label>
    </div>
  );
};

Checkbox.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  disabled: PropTypes.bool,
};

export default Checkbox;
